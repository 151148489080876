import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DatePipe} from '@angular/common';
import {ActivatedRoute} from "@angular/router";
import { BASE_URL } from '../config/globals';

declare var $: any;

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class BusinessComponent implements OnInit {

  data: any={};
  adminInfo: any={};
  results: any={};
  month = '';
  year = '';
  private businessId;

  entityKey;
  userRole;

  formGroupAddConsultant: FormGroup;
  dataResults: any = {};
  private id;

  resultMessage;
  confirmText;
  confirmType;

  tempBusinessAdminId;


  constructor(private httpClient: HttpClient, private datePipe: DatePipe, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {

    if (sessionStorage.getItem("entityId") !== null && sessionStorage.getItem("entityId") !== '') {
      this.businessId = sessionStorage.getItem("entityId");
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.entityKey = sessionStorage.getItem("entityKey");
    this.userRole = sessionStorage.getItem("userRole");
    /*this.activatedRoute.queryParams.subscribe(params => {
      if (params['id'] != null) {
        this.businessId = params['id'];
      }
    });*/

    this.get_businessResults();
    this.get_businessInfo();

    this.month = this.datePipe.transform(new Date(), "MMMM");
    this.year = this.datePipe.transform(new Date(), "yyyy");

  }

  get_businessResults() {
    const params = new HttpParams().set('businessId', this.businessId);
    this.httpClient.get(BASE_URL + '/businessResource/getBusinessDashBoardInfo',{params}).subscribe((res) => {
      //console.log(res);
      this.data = res;
    });
  }


  get_businessInfo() {
    const params = new HttpParams().set('businessId', this.businessId);
    this.httpClient.get(BASE_URL + '/businessResource/getBusinessInfo?',{params}).subscribe((res) => {
      //console.log(res);
      this.adminInfo = res;
    });
  }

  confirmDeactivateBusinessAdmin(businessAdminId) {

    this.confirmType = 'DEACTIVATE-BUSINESS-ADMIN';
    this.confirmText = 'Confirm deactivation of business admin?';
    this.tempBusinessAdminId = businessAdminId;
    $('#confirmModal').modal('show');
  }

  confirmActivateBusinessAdmin(businessAdminId) {

    this.confirmType = 'ACTIVATE-BUSINESS-ADMIN';
    this.confirmText = 'Confirm activation of business admin?';
    this.tempBusinessAdminId = businessAdminId;
    $('#confirmModal').modal('show');
  }

  deactivateBusinessAdmin() {
    this.httpClient.put(BASE_URL + "/businessAdminResource/deactivateBusinessAdmin?businessAdminId=" + this.tempBusinessAdminId,
      {"businessAdminId" : this.tempBusinessAdminId
      })
      .subscribe(
        data => {
          this.dataResults = data;
          if (this.dataResults.success === true && this.dataResults.httpStatus === "OK") {
            this.resultMessage = 'Business administrator successfully deactivated';
            $('#resultModal').modal('show');
          } else {
            this.resultMessage = this.dataResults.message;
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'Error occurred deactivating the business administrator. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }

  activateBusinessAdmin() {
    this.resultMessage = 'Under construction';
    $('#resultModal').modal('show');
  }

}
