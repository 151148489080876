import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {HttpParams} from  "@angular/common/http";
import { BASE_URL } from '../config/globals';

@Component({
  selector: 'app-trailer',
  templateUrl: './trailer.component.html',
  styleUrls: ['./trailer.component.scss']
})
export class TrailerComponent implements OnInit {

  private trailerId = '';
  data: any={};

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.trailerId = params['id'];
      console.log(this.trailerId);
    });
    this.getResults();
  }

  //TODO get correct URL and map values to HTML
  getResults() {
    const params = new HttpParams().set('trailerId', this.trailerId);
    this.httpClient.get(BASE_URL + '/trailerResource/getTrailerById?trailerId=1', {params}).subscribe((res) => {
      //console.log(res);
      this.data = res;
    });

  }

}
