import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BASE_URL, LOGIN_URL} from '../config/globals';

declare var $: any;

/**
 *
 * @author Megan
 */

@Component({
  selector: 'app-admin-auth',
  templateUrl: './admin-auth.component.html',
  styleUrls: ['./admin-auth.component.scss']
})
export class AdminAuthComponent implements OnInit {

  dataResults: any={};
  authResponse: any={};


  formAuthentication: FormGroup;

  entityId;

  authenticated = false;

  constructor(private httpClient: HttpClient) {
  }

  ngOnInit() {

    $('#authModal').modal('show');
    this.authenticationForm();
  }





  authenticationForm(){
    this.formAuthentication = new FormGroup({
      userName: new FormControl('', [
        Validators.required
      ]),
      password: new FormControl('', [
        Validators.required
      ])
    });
  }

  cancelAuth() {
    window.location.href = "/403?message=You are not authorized to view this page";
  }


  authenticateAdmin() {

    let userName = (<HTMLInputElement>document.getElementById("userName")).value;
    let password = (<HTMLInputElement>document.getElementById("authPassword")).value;

    const params = new HttpParams().set('userName', userName).set("password", password);
    this.httpClient.get(LOGIN_URL + '/authenticationResource/authAdmin', {params}).subscribe((res) => {
      this.authResponse = res;

      if (this.authResponse.success !== true || this.authResponse.httpStatus !== "OK") {
        //$('#authModal').modal('hide');
        window.location.href = "/403?message=You are not authorized to view this page";
      } else if (this.authResponse.success === true && this.authResponse.httpStatus === "OK") {
        window.location.href = "/admin?token=tyzg";
      }
    });
  }

}
