import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {HttpParams} from "@angular/common/http";
import {DatePipe} from '@angular/common';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { BASE_URL } from '../config/globals';

declare var $: any;

/**
 *
 * @author Megan
 */

@Component({
  selector: 'app-business-admin',
  templateUrl: './add-business-admin.component.html',
  styleUrls: ['./add-business-admin.component.scss']
})
export class AddBusinessAdminComponent implements OnInit {

  data: any = {};

  formGroupAddBusinessAdmin: FormGroup;
  dataResults: any = {};

  businessId;

  userRole;
  entityId;

  resultMessage;
  confirmText;
  confirmType;

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private datePipe: DatePipe) {
  }

  ngOnInit() {

    if (sessionStorage.getItem("entityId") !== null && sessionStorage.getItem("entityId") !== '') {
      this.businessId = sessionStorage.getItem("entityId");
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.userRole = sessionStorage.getItem("userRole");
    this.entityId = sessionStorage.getItem("entityId");

    this.validationBusinessAdmin();
  }

  confirmAddBusinessAdmin() {
    this.confirmType = 'ADD-BUSINESS-ADMIN';
    this.confirmText = 'Confirm add of new business administrator?';
    $('#confirmModal').modal('show');

  }


  validationBusinessAdmin() {
    this.formGroupAddBusinessAdmin = new FormGroup({
      businessAdminName: new FormControl('', [
        Validators.required
      ]),
      businessAdminSurname: new FormControl('', [
        Validators.required
      ]),
      businessAdminEmailAddress: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
      businessAdminContactNumber: new FormControl('', [
        Validators.required
      ]),
      businessAdminIdNumber: new FormControl('', [
        Validators.required
      ])
    });
  }


  addBusinessAdministrator() {

    let emailAddress = (<HTMLInputElement> document.getElementById("businessAdminEmailAddress")).value;

    this.httpClient.post(BASE_URL + "/businessAdminResource/addBusinessAdmin",
      {
        "businessId": this.businessId,
        "name": (<HTMLInputElement> document.getElementById("businessAdminName")).value,
        "surname": (<HTMLInputElement> document.getElementById("businessAdminSurname")).value,
        "emailAddress": emailAddress,
        "contactNumber": (<HTMLInputElement> document.getElementById("businessAdminContactNumber")).value,
        "entityStatus": 'PENDING',
        "userName": emailAddress,
        "password": 'password',
        "identificationNumber": (<HTMLInputElement> document.getElementById("businessAdminIdNumber")).value
      })
      .subscribe(
        data => {
          this.dataResults = data;
          if (this.dataResults.success === true && this.dataResults.httpStatus === "OK") {
            this.resultMessage = 'Business administrator successfully added.';
            $('#resultModal').modal('show');
          } else {
            this.resultMessage = 'An error occurred adding new business administrator. Please contact support';
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'An error occurred adding new business administrator. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }

  cancel() {
    this.redirect();
  }

  complete() {
    this.redirect();
  }

  redirect() {

    if (this.userRole === 'BUSINESS') {
      window.location.href = "/business?id=" + this.entityId;
    } else if (this.userRole === 'BUSINESSADMIN') {
      window.location.href = "/business-admin?id=" + this.entityId;
    } else if (this.userRole === 'CONSULTANT') {
      window.location.href = "/consultant?consultantId=" + this.entityId;
    } else if (this.userRole === 'SUBCONTROLLER') {
      window.location.href = "/sub-controller";
    } else if (this.userRole === 'SUBCONTRACTOR') {
      window.location.href = "/sub-contractor?id=" + this.entityId;
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }
  }
}
