import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AddSubControllerComponent} from "./add-sub-controller.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@NgModule({
  declarations: [AddSubControllerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    AddSubControllerComponent
  ]
})
export class AddSubControllerModule{
}
