import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {DriverListComponent} from "./driver-list.component";

@NgModule({
  declarations: [DriverListComponent],
  imports: [
    CommonModule
  ],
  exports: [
    DriverListComponent
  ]
})
export class DriverListModule{
}
