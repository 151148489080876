import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {BusinessComponent} from "./business.component";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [BusinessComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    BusinessComponent
  ]
})
export class BusinessModule{
}
