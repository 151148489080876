import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BusinessAdminComponent} from "./business-admin.component";

@NgModule({
  declarations: [BusinessAdminComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    BusinessAdminComponent
  ]
})
export class BusinessAdminModule{
}
