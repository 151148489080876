import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AddBusinessAdminComponent} from "./add-business-admin.component";

@NgModule({
  declarations: [AddBusinessAdminComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    AddBusinessAdminComponent
  ]
})
export class AddBusinessAdminModule{
}
