import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {HttpParams} from  "@angular/common/http";
import {Location} from '@angular/common';
import {Observable} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { BASE_URL } from '../config/globals';

declare var $: any;

@Component({
  selector: 'app-truck',
  templateUrl: './add-truck.component.html',
  styleUrls: ['./add-truck.component.scss']
})
export class AddTruckComponent implements OnInit {

  private truckId = '';
  data: any={};
  dataResults: any={};
  availableDrivers: any={};
  subcontrollerId;
  subcontractorId;
  alive = true;

  resultMessage;
  resultButtonText;
  truckAddSuccess = false;
  userRoleSubcontractor = false;

  entityId;
  userRole;

  formGroupAddTruck: FormGroup;

  allocateDriverResponse;
  removeDriverFromTruckResponse;

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private location: Location) {

    /*Observable.timer(0,15000)
      .takeWhile(() => this.alive) // only fires when component is alive
      .subscribe(() => {
        console.log("Polling...");
        this.getAvailableDrivers();
      });*/
  }

  ngOnInit() {

    this.userRole = sessionStorage.getItem("userRole");
    this.entityId = sessionStorage.getItem("entityId");

    if (this.userRole === 'SUBCONTROLLER' && this.entityId !== null && this.entityId !== '') {
      this.subcontrollerId = this.entityId;
      this.userRoleSubcontractor = false;
    } else if (this.userRole === 'SUBCONTRACTOR' && this.entityId !== null && this.entityId !== '') {
      this.subcontractorId = this.entityId;
      this.userRoleSubcontractor = true;
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    /*this.activatedRoute.queryParams.subscribe(params => {

    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.subcontrollerId = params['subcontrollerId'];
    });*/

    if (this.userRoleSubcontractor) {
      this.getSubcontractorDashboardInfo();
      this.validationSubcontractorTruck();
    } else {
      this.validationSubcontrollerTruck();
    }


  }

  getSubcontractorDashboardInfo() {
    const params = new HttpParams().set('subcontractorId', this.subcontractorId);
    this.httpClient.get(BASE_URL + '/subcontractorResource/getSubcontractorDashboardInfo', {params}).subscribe((res) => {
      console.log(res);
      this.data = res;
    });
  }

  validationSubcontractorTruck() {
    this.formGroupAddTruck = new FormGroup({
      truckVIN: new FormControl('', [
        Validators.required
      ]),
      truckRegistrationNumber: new FormControl('', [
        Validators.required
      ]),
      truckMake: new FormControl('', [
        Validators.required
      ]),
      truckModel: new FormControl('', [
        Validators.required
      ]),
      truckYear: new FormControl('', [
        Validators.required
      ]),
      /*truckAxleCount: new FormControl('', [
        Validators.required
      ]),*/
      truckStatus: new FormControl('', [
        Validators.required
      ]),
      truckType: new FormControl('', [
        Validators.required
      ]),
      subcontroller: new FormControl('', [
        Validators.required
      ])
    });
  }

  validationSubcontrollerTruck() {
    this.formGroupAddTruck = new FormGroup({
      truckVIN: new FormControl('', [
        Validators.required
      ]),
      truckRegistrationNumber: new FormControl('', [
        Validators.required
      ]),
      truckMake: new FormControl('', [
        Validators.required
      ]),
      truckModel: new FormControl('', [
        Validators.required
      ]),
      truckYear: new FormControl('', [
        Validators.required
      ]),
      /*truckAxleCount: new FormControl('', [
        Validators.required
      ]),*/
      truckStatus: new FormControl('', [
        Validators.required
      ]),
      truckType: new FormControl('', [
        Validators.required
      ])
    });
  }

  cancel() {
    this.redirect();
  }

  redirect() {

    if (this.userRole === 'BUSINESS') {
      window.location.href = "/business?id=" + this.entityId;
    } else if (this.userRole === 'BUSINESSADMIN') {
      window.location.href = "/business-admin?id=" + this.entityId;
    } else if (this.userRole === 'CONSULTANT') {
      window.location.href = "/consultant?consultantId=" + this.entityId;
    } else if (this.userRole === 'SUBCONTROLLER') {
      window.location.href = "/sub-controller";
    } else if (this.userRole === 'SUBCONTRACTOR') {
      window.location.href = "/sub-contractor?id=" + this.entityId;
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }
  }

  complete() {
    if (this.truckAddSuccess) {
        window.location.href = '/truck?id='+this.dataResults.truck.truckId+'&subcontrollerId='+this.subcontrollerId;
    } else {
      this.cancel();
    }
  }

  add_Truck() {

    let localSubcontrollerId;
    let localSubcontractorId;

    if (this.userRoleSubcontractor) {
      localSubcontrollerId = (<HTMLInputElement> document.getElementById("subcontroller")).value;
      localSubcontractorId = this.data.subcontractorId;
      this.subcontrollerId = localSubcontrollerId;
    } else {
      localSubcontrollerId = this.subcontrollerId;
      localSubcontractorId = '';
    }

    this.httpClient.post(BASE_URL + "/truckResource/addTruck",
      {
        "vin": (<HTMLInputElement> document.getElementById("truckVIN")).value,
        "registrationNumber": (<HTMLInputElement> document.getElementById("truckRegistrationNumber")).value,
        "make": (<HTMLInputElement> document.getElementById("truckMake")).value,
        "model": (<HTMLInputElement> document.getElementById("truckModel")).value,
        "year": (<HTMLInputElement> document.getElementById("truckYear")).value,
        "axleCount": "N/A",
        "truckStatus": (<HTMLInputElement> document.getElementById("truckStatus")).value,
        "truckType": (<HTMLInputElement> document.getElementById("truckType")).value,
        "subContractorId": localSubcontractorId,
        "subcontrollerId": localSubcontrollerId,
      })
      .subscribe(
        data => {
          this.dataResults = data;
          if (this.dataResults.success === true && this.dataResults.httpStatus === "OK") {
            console.log("POST Request is successful ", data);
            //$('#successAlert').removeClass('hidden').addClass('show');
            this.resultMessage = "Truck successfully added";
            this.resultButtonText = "Continue";
            this.truckAddSuccess = true;
            $('#resultModal').modal('show');
          } else {
            //$('#errorAlert').removeClass('hidden').addClass('show');
            this.resultMessage = "An error occurred adding the truck.";
            this.resultButtonText = "Cancel";
            this.truckAddSuccess = false;
            $('#resultModal').modal('show');
            console.log("Error", data);
          }
        },
        error => {
          console.log("Error", error);
          //$('#myAlert4').removeClass('hidden').addClass('show');
        }
      );
   /* $('#truckModal').modal('hide');
    $('#truckModal').on('hidden.bs.modal', function () {
      $(this).find('form').trigger('reset');
    })*/
  }

  /**
   * This function gets all the drivers not allocated to a truck
   */
  getAvailableDrivers() {
    const params = new HttpParams().set('entityId', this.subcontrollerId).set("userRole", this.userRole);
    this.httpClient.get(BASE_URL +  '/driverResource/getAvailableDrivers', {params}).subscribe((res) => {
      //console.log(res);
      this.availableDrivers = res;
    });
  }

  /**
   * Allocates a driver to a truck
   * @param driverId
   */
  allocateDriver(driverId) {

    this.httpClient.put(BASE_URL + "/truckResource/assignDriverToTruck?truckId=" + this.truckId + "&driverId=" + driverId + "&override=false",

      {
        "driverId": driverId,
        "truckId": this.truckId,
        "override": false
      })

      .subscribe(
        data => {
          this.allocateDriverResponse = data;
          if (this.allocateDriverResponse.success === true && this.allocateDriverResponse.httpStatus === "OK") {
            /*$('#myAlert1').removeClass('hidden').addClass('show');
            console.log("PUT Request is successful ", data);*/
            alert("Success");
            this.getAvailableDrivers();
          } else {
            /*$('#myAlert2').removeClass('hidden').addClass('show');
            console.log("Error", data);*/
            alert("Error");
          }
        },
        error => {
          alert("Error");
        }
      );
  }

  ngOnDestroy(){
    this.alive = false; // switches your IntervalObservable off
  }
}
