import {Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import {MapsAPILoader, MouseEvent } from '@agm/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DomSanitizer} from '@angular/platform-browser';
import {DatePipe} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {google} from '@google/maps';
import { BASE_URL } from '../config/globals';


declare var $: any;
declare var google;

@Component({
  selector: 'app-addContract',
  templateUrl: './update-contract.component.html',
  styleUrls: ['./update-contract.component.scss']
})
export class UpdateContractComponent implements OnInit {

  pickupLatitude;
  pickupLongitude;

  dropOffLatitude;
  dropOffLongitude;

  zoom;

  loadTime;

  resultButtonText;
  resultMessage;
  addSuccess = false;

  public searchControl: FormControl;

  @ViewChild("pickupSearch")
  public pickupSearchElementRef: ElementRef;

  @ViewChild("dropOffSearch")
  public dropOffSearchElementRef: ElementRef;

  address;
  geoCoder;
  pickup='';
  dropOff='';

  userRole;
  entityId;
  contractId;

  pickupGpsCoordinates = '';
  dropOffGpsCoordinates = '';
  month = '';
  year = '';
  formGroupUpdateContract: FormGroup;

  dataResults: any={};
  contractInfo: any={};

  defaultValue = "UNASSIGNED";
  defaultTrailer = 'UNASSIGNED';

  constructor(private httpClient: HttpClient, public sanitizer: DomSanitizer,
              private datePipe: DatePipe, private activatedRoute: ActivatedRoute,
              private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone) {}

  ngOnInit() {

    if (sessionStorage.getItem("userRole") === null || sessionStorage.getItem("userRole") === '' ||
        sessionStorage.getItem("entityId") === null || sessionStorage.getItem("entityId") === '') {
        sessionStorage.clear();
        window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.userRole = sessionStorage.getItem("userRole");
    this.entityId = sessionStorage.getItem("entityId");
    this.contractId = sessionStorage.getItem("contractId");

    //create search FormControl
    this.searchControl = new FormControl();
    this.setCurrentLocation();

    this.mapsAPILoader.load().then(() => {

      this.geoCoder = new google.maps.Geocoder();

      let loadLocationAutoComplete = new google.maps.places.Autocomplete(this.pickupSearchElementRef.nativeElement);
      let dropoffLocationAutoComplete = new google.maps.places.Autocomplete(this.dropOffSearchElementRef.nativeElement);

      loadLocationAutoComplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let pickupPlace: google.maps.places.PlaceResult = loadLocationAutoComplete.getPlace();

          //verify result
          if (pickupPlace.geometry === undefined || pickupPlace.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.pickupLatitude = pickupPlace.geometry.location.lat();
          this.pickupLongitude = pickupPlace.geometry.location.lng();
          this.getPickupFormattedAddress(this.pickupLatitude, this.pickupLongitude);
          this.zoom = 12;
        });
      });

      dropoffLocationAutoComplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let dropoffPlace: google.maps.places.PlaceResult = dropoffLocationAutoComplete.getPlace();

          //verify result
          if (dropoffPlace.geometry === undefined || dropoffPlace.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.dropOffLatitude = dropoffPlace.geometry.location.lat();
          this.dropOffLongitude = dropoffPlace.geometry.location.lng();

          this.getDropoffFormattedAddress(this.dropOffLatitude, this.dropOffLongitude);

          this.zoom = 12;
        });
      });
    });

    this.validationLoad();
    this.getContractInfo();

    this.pickupLatitude = this.contractInfo.contractInfoDto.pickupLatitude;
    this.pickupLongitude = this.contractInfo.contractInfoDto.pickupLongitude;

    this.dropOffLatitude = this.contractInfo.contractInfoDto.dropoffLatitude;
    this.dropOffLongitude = this.contractInfo.contractInfoDto.dropoffLongitude;

    this.pickup = this.contractInfo.contractInfoDto.loadPickupLocation;
    this.dropOff = this.contractInfo.contractInfoDto.loadDropoffLocation;

  }

  getContractInfo() {

    const params = new HttpParams().set('entityId', this.entityId).set('userRole', this.userRole).set("contractId", this.contractId).set("contractNumber", null);
    this.httpClient.get(BASE_URL + '/contractResource/getContractInfo', {params}).subscribe((res) => {
      this.contractInfo = res;
    });
  }

  complete() {
    window.location.href = "/contract-management";
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.pickupLatitude = position.coords.latitude;
        this.pickupLongitude = position.coords.longitude;

        this.dropOffLatitude = position.coords.latitude;
        this.dropOffLongitude = position.coords.longitude;

        this.zoom = 8;
        this.getPickupFormattedAddress(this.pickupLatitude, this.pickupLongitude);
        this.getDropoffFormattedAddress(this.dropOffLatitude, this.dropOffLongitude);
      });
    }
  }

  pickupMarkerDragEnd($event: MouseEvent) {
    console.log($event);
    this.pickupLatitude = $event.coords.lat;
    this.pickupLongitude = $event.coords.lng;
    this.getPickupFormattedAddress(this.pickupLatitude, this.pickupLongitude);
  }

  dropOffMarkerDragEnd($event: MouseEvent) {
    console.log($event);
    this.dropOffLatitude = $event.coords.lat;
    this.dropOffLongitude = $event.coords.lng;
    this.getDropoffFormattedAddress(this.pickupLatitude, this.pickupLongitude);
  }

  getPickupFormattedAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.pickup = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  getDropoffFormattedAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.dropOff = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  saveContract() {
    this.addContract();
  }

  cancel() {
    history.back();
  }

  redirect() {

    if (this.userRole === 'BUSINESS') {
      window.location.href = "/business?id=" + this.entityId;
    } else if (this.userRole === 'BUSINESSADMIN') {
      window.location.href = "/business-admin?id=" + this.entityId;
    } else if (this.userRole === 'CONSULTANT') {
      window.location.href = "/consultant?consultantId=" + this.entityId;
    } else if (this.userRole === 'SUBCONTROLLER') {
      window.location.href = "/sub-controller";
    } else if (this.userRole === 'SUBCONTRACTOR') {
      window.location.href = "/sub-contractor?id=" + this.entityId;
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }
  }

  validationLoad() {
    //validation Load
    this.formGroupUpdateContract = new FormGroup({
      contractNumber: new FormControl('', [
        Validators.required
      ]),
      commencement: new FormControl('', [
        Validators.required
      ]),
      duration: new FormControl('', [
        Validators.required
      ]),
      loadRate: new FormControl('', [
        Validators.required
      ]),
      commodity: new FormControl('', [
        Validators.required
      ]),
      trailerType: new FormControl('', [
        Validators.required
      ]),
      totalTonnage: new FormControl('', [
        Validators.required
      ]),
      truckCountRequired: new FormControl('', [
        Validators.required
      ]),
    });
  }

  addContract(){

    // this gives a true or false
    let pub = (<HTMLInputElement> document.getElementById("public")).checked;

    let payType;

    if ((<HTMLInputElement> document.getElementById("radio1")).checked) {
      payType = "TON";
    } else if ((<HTMLInputElement> document.getElementById("radio2")).checked) {
      payType = "LOAD";
    } else if ((<HTMLInputElement> document.getElementById("radio3")).checked) {
      payType = "KM";
    } else {
      payType = "UNSPECIFIED";
    }

    this.httpClient.post(BASE_URL + "/contractResource/updateContract",
      {
        "contractId": this.contractId,
        "userRole": this.userRole,
        "entityId": this.entityId,
        "contractNumber": (<HTMLInputElement> document.getElementById("contractNumber")).value,
        "contractCommencementDate": (<HTMLInputElement> document.getElementById("commencement")).value,
        "client": "N/A",
        "loadPickupLocation": this.pickup,
        "loadDropoffLocation" : this.dropOff,
        "pickupGpsCoordinates" : this.pickupLatitude + "|" + this.pickupLongitude,
        "dropoffGpsCoordinates" : this.dropOffLatitude + "|" + this.dropOffLongitude,
        "contractRate": (<HTMLInputElement> document.getElementById("loadRate")).value,
        "ratePaymentType": payType,
        "trailerType": (<HTMLInputElement> document.getElementById("trailerType")).value,
        "contractLength": (<HTMLInputElement> document.getElementById("duration")).value,
        "publicContract": pub,
        "commodity": (<HTMLInputElement> document.getElementById("commodity")).value,
        "truckCountRequired": (<HTMLInputElement> document.getElementById("truckCountRequired")).value,
        "totalTonnage": (<HTMLInputElement> document.getElementById("totalTonnage")).value,
      })
      .subscribe(
        data  => {
          this.dataResults = data;
          if (this.dataResults.success === true && this.dataResults.httpStatus === "OK") {
            this.resultMessage = this.dataResults.message;
            this.resultButtonText = "Continue";
            this.addSuccess = true;
            $('#resultModal').modal('show');
          } else {
            this.resultMessage = this.dataResults.message;
            this.resultButtonText = "Close";
            this.addSuccess = false;
            $('#resultModal').modal('show');
          }
        },
        error  => {
          this.resultMessage = this.dataResults.message;
          this.resultButtonText = "Close";
          this.addSuccess = false;
          $('#resultModal').modal('show');
        }
      );
  }
}
