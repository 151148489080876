import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {HttpParams} from "@angular/common/http";
import {DatePipe} from '@angular/common';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { BASE_URL } from '../config/globals';

declare var $: any;

/**
 *
 * @author Megan
 */

@Component({
  selector: 'manage-consultants',
  templateUrl: './manage-consultants.component.html',
  styleUrls: ['./manage-consultants.component.scss']
})
export class ManageConsultantsComponent implements OnInit {

  data: any = {};
  consultantsLinkedToBusinessAdmin: any = {};
  consultantInfo = null;

  dataResults: any = {};

  tempConsultantId;
  businessAdminId;

  userRole;
  entityId;

  resultMessage;
  confirmText;
  confirmType;

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private datePipe: DatePipe) {
  }

  ngOnInit() {

    if (sessionStorage.getItem("userRole") === null || sessionStorage.getItem("userRole") !== 'BUSINESSADMIN') {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    if (sessionStorage.getItem("entityId") !== null && sessionStorage.getItem("entityId") !== '') {
      this.businessAdminId = sessionStorage.getItem("entityId");
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.userRole = sessionStorage.getItem("userRole");
    this.entityId = sessionStorage.getItem("entityId");

    this.getConsultantsLinkedToBusinessAdmin();
  }

  getConsultantsLinkedToBusinessAdmin() {
    const params = new HttpParams().set('businessAdminId', this.businessAdminId);
    this.httpClient.get(BASE_URL + '/businessAdminResource/getBusinessAdminInfo',{params}).subscribe((res)=>{
      this.consultantsLinkedToBusinessAdmin = res;
    });
  }

  getConsultantInfo(consultantId){
    this.tempConsultantId = consultantId;
    const params = new HttpParams().set('consultantId', consultantId);
    this.httpClient.get(BASE_URL + '/consultantResource/getConsultantInfo',{params}).subscribe((res)=>{
      this.consultantInfo = res;
    });
  }

  assignConsultant(subcontractorId) {

    let consultantId = (<HTMLInputElement> document.getElementById(subcontractorId)).value;

    this.httpClient.put(BASE_URL + "/assignmentResource/assignSubcotractorToConsultant?consultantId=" + consultantId + "&originalConsultantId=" + this.tempConsultantId + "&subcontractorId=" + subcontractorId,
      {"consultantId" : consultantId,
             "originalConsultantId": this.tempConsultantId,
             "subcontractorId": subcontractorId
      })
      .subscribe(
        data => {
          this.dataResults = data;
          if (this.dataResults.success === true && this.dataResults.httpStatus === "OK") {
            this.resultMessage = this.dataResults.message;
            $('#resultModal').modal('show');
            this.getConsultantInfo(this.tempConsultantId);
          } else {
            this.resultMessage = this.dataResults.message;
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'Error occurred reassigning the subcontractor. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }

  confirmAddBusinessAdmin() {
    this.confirmType = 'ADD-BUSINESS-ADMIN';
    this.confirmText = 'Confirm add of new business administrator?';
    $('#confirmModal').modal('show');

  }

  cancel() {
    this.redirect();
  }

  complete() {
    this.redirect();
  }

  redirect() {

    if (this.userRole === 'BUSINESS') {
      window.location.href = "/business?id=" + this.entityId;
    } else if (this.userRole === 'BUSINESSADMIN') {
      window.location.href = "/business-admin?id=" + this.entityId;
    } else if (this.userRole === 'CONSULTANT') {
      window.location.href = "/consultant?consultantId=" + this.entityId;
    } else if (this.userRole === 'SUBCONTROLLER') {
      window.location.href = "/sub-controller";
    } else if (this.userRole === 'SUBCONTRACTOR') {
      window.location.href = "/sub-contractor?id=" + this.entityId;
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }
  }
}
