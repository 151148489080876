import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {HttpParams} from  "@angular/common/http";
import {Location} from '@angular/common';
import {Observable} from "rxjs";
import { BASE_URL } from '../config/globals';


declare var $: any;

@Component({
  selector: 'app-truck',
  templateUrl: './truck-management.component.html',
  styleUrls: ['./truck-management.component.scss']
})
export class TruckManagementComponent implements OnInit {

  private truckId = '';
  data: any={};
  availableTrucks: any={};

  entityId;
  userRole;

  resultMessage
  confirmText;
  confirmType;

  driverId;
  trailerId;

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private location: Location) {

    /*Observable.timer(0,15000)
      .takeWhile(() => this.alive) // only fires when component is alive
      .subscribe(() => {
        console.log("Polling...");
        this.getAvailableDrivers();
        this.getDriversLinkedToTruck();
        this.getAvailableTrailers();
        this.getLinkedTrailers();
      });*/
  }

  ngOnInit() {
    if (sessionStorage.getItem("entityId") !== null && sessionStorage.getItem("entityId") !== '') {
      this.entityId = sessionStorage.getItem("entityId");
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.userRole = sessionStorage.getItem("userRole");

    this.getTrucksWithDrivers();
  }


  /*getAvailableTrucks(): any {
    const params = new HttpParams().set("subControllerId", this.subcontrollerId);
    this.httpClient.get(BASE_URL +'/subControllerResource/getAvailableTrucks', {params: params}).subscribe((res)=>{
      this.data = res;
    });
    return this.data;
  }*/

  getTrucksWithDrivers(){
    let params;

    if (this.userRole === 'SUBCONTRACTOR') {
      params = new HttpParams().set('subcontractorId', this.entityId).set("subcontrollerId", "");
    } else if (this.userRole === 'SUBCONTROLLER') {
      params = new HttpParams().set('subcontractorId', "").set("subcontrollerId", this.entityId);
    }

    this.httpClient.get(BASE_URL + '/truckResource/getTrucksWithDriverCount',{params}).subscribe((res)=>{
      this.data = res;
    });
  }

}
