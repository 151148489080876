import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpParams} from "@angular/common/http";
import { BASE_URL } from '../config/globals';

declare var $: any;

@Component({
  selector: 'view-app-sub-contractor',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.scss']
})
export class OpportunitiesComponent implements OnInit {

  opportunityList: any={};
  trucks: any={};

  requestLinkResponse: any={};

  month = '';
  year = '';
  subcontractorId;
  userRole;


  resultMessage;
  confirmText;
  confirmType;

  tempBusinessToSubcontractorRequirementId;
  tempBusinessId;
  tempSubcontractorToBusinessRequestId;

  constructor(private httpClient: HttpClient, private datePipe: DatePipe, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['id'] != null) {
        this.subcontractorId = params['id'];
      }
    });

    this.userRole = sessionStorage.getItem("userRole");
    this.getAvailableOpportunities();
  }

  getAvailableOpportunities() {
    const params = new HttpParams().set('subcontractorId', this.subcontractorId);
    this.httpClient.get(BASE_URL + '/subcontractorResource/getAvailableOpportunities', {params}).subscribe((res) => {
      this.opportunityList = res;
    });
  }


  confirmRequestSubcontract(brokerName, businessToSubcontractorRequirementId, businessId) {
    this.confirmType = 'REQUEST';
    this.tempBusinessToSubcontractorRequirementId = businessToSubcontractorRequirementId;
    this.tempBusinessId = businessId;
    this.confirmText = 'Confirm request to contract for ' + brokerName + '?';
    $('#confirmModal').modal('show');
  }

  confirmRemoveSubcontractRequest(brokerName, subcontractorToBusinessRequestId) {
    this.confirmType = 'REMOVE';
    this.tempSubcontractorToBusinessRequestId = subcontractorToBusinessRequestId;

    this.confirmText = 'Confirm remove request to contract for ' + brokerName + '?';
    $('#confirmModal').modal('show');
  }

  actionLinkRequest() {
    this.httpClient.put(BASE_URL + "/subcontractorResource/requestLinkToBroker?businessId=" + this.tempBusinessId + "&subcontractorId=" + this.subcontractorId + "&businessToSubcontractorRequirementId=" + this.tempBusinessToSubcontractorRequirementId,

      {
        "businessId": this.tempBusinessId,
        "subcontractorId": this.subcontractorId,
        "businessToSubcontractorRequirementId": this.tempBusinessToSubcontractorRequirementId
      })

      .subscribe(
        data => {
          this.requestLinkResponse = data;
          if (this.requestLinkResponse.success === true && this.requestLinkResponse.httpStatus === "OK") {
            this.resultMessage = 'Link successfully requested';
            this.getAvailableOpportunities()
            $('#resultModal').modal('show');
          } else {
            this.resultMessage = 'An error occurred with the request. Please contact support';
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'An error occurred with the request. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }

  actionLinkRemoval() {


      this.httpClient.put(BASE_URL + "/subcontractorResource/removeLinkRequest?subcontractorToBusinessRequestId=" + this.tempSubcontractorToBusinessRequestId,

        {
          "businessToSubcontractorRequirementId": this.tempSubcontractorToBusinessRequestId
        })

        .subscribe(
          data => {
            this.requestLinkResponse = data;
            if (this.requestLinkResponse.success === true && this.requestLinkResponse.httpStatus === "OK") {
              this.resultMessage = 'Link request successfully removed';
              this.getAvailableOpportunities()
              $('#resultModal').modal('show');
            } else {
              this.resultMessage = 'An error occurred with the request. Please contact support';
              $('#resultModal').modal('show');
            }
          },
          error => {
            this.resultMessage = 'An error occurred with the request. Please contact support';
            $('#resultModal').modal('show');
          }
        );
    }


}
