import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Location} from "@angular/common";

/**
 *
 * @author Megan
 */

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  private urlPath;
  showing = {
    ready: false,
    mustShow: false
  };

  constructor(private router: Router,
              private location: Location) {
    this.urlPath = this.location.path();
  }

  ngOnInit() {
    this.subscribeToRouteEvents();
    this.showing.ready = true;
  }

  subscribeToRouteEvents() {
    if (this.urlPath === "/login" || this.urlPath === "/forgot-password" || this.urlPath === "/pickup-location" || this.urlPath === "/dropoff-location"
      || this.urlPath === "/401" || this.urlPath === "/403" || this.urlPath === "/404" || this.urlPath.includes('/register-business')) {
      this.showing.mustShow = false;
    }
    else if (this.urlPath) {
      this.showing.mustShow = true;
    }

  }

  scrollToTop(){
    window.scrollTo(0,0);
  }


}
