import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {Error403Component} from "./error403.component";

@NgModule({
  declarations: [Error403Component],
  imports: [
    CommonModule
  ],
  exports: [
    Error403Component
  ]
})
export class Error403Module{
}
