import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AdminAuthComponent} from "./admin-auth.component";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [AdminAuthComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    AdminAuthComponent
  ]
})
export class AdminAuthModule{
}
