import {NgModule} from "@angular/core";
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from "@angular/common";
import {RegisterBusinessComponent} from "./register-business.component";
import {AgmCoreModule} from "@agm/core";


@NgModule({
  declarations: [RegisterBusinessComponent],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAR8J9QbcE44i7DJN2vcBaDXzHK52Llf9s',
      libraries: ["places"]
    })
  ],
  exports: [
    RegisterBusinessComponent
  ]
})
export class RegisterBusinessModule {
}
