import {Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import {MapsAPILoader, MouseEvent } from '@agm/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from '@angular/router';
import {google} from '@google/maps';
import { LOGIN_URL } from '../config/globals';

declare var $: any;
declare var google;

/**
 *
 * @author Megan
 */

@Component({
  selector: 'app-register-business',
  templateUrl: './register-business.component.html',
  styleUrls: ['./register-business.component.scss']
})
export class RegisterBusinessComponent implements OnInit {

  formGroupAddBusiness: FormGroup;
  dataResults: any = {};

  geoCoder;
  location;
  latitude;
  longitude;
  zoom;
  entityType;

  resultMessage;

  screenMessage;
  regEx = new RegExp('/\\S+@\\S+\\.\\S+/');

  public searchControl: FormControl;
  @ViewChild("locationSearch")
  public locationSearch: ElementRef;

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute,
              private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone) {
  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['t'] != null) {
        this.entityType = params['t'];
      }
    });

    if (this.entityType === '4HZ2') {
      this.entityType = 'CLIENT';
      this.screenMessage = 'Register as a client to access the system and supply loads to available subcontractors.';
    } else if (this.entityType === '4HZ4'){
      this.entityType = 'SUBCONTRACTOR';
      this.screenMessage = 'Register as a subcontractor to access the system and provide logistic services to clients.';
    }

    if (this.entityType === 'CLIENT') {
      this.addBusinessValidation();
    } else if (this.entityType === 'SUBCONTRACTOR') {
      this.addSubcontractorValidation();
    } else {
      window.location.href = '/login';
    }


    //create search FormControl
    this.searchControl = new FormControl();
    this.setCurrentLocation();


    this.mapsAPILoader.load().then(() => {

      this.geoCoder = new google.maps.Geocoder();

      let loadLocationAutoComplete = new google.maps.places.Autocomplete(this.locationSearch.nativeElement);


      loadLocationAutoComplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let pickupPlace: google.maps.places.PlaceResult = loadLocationAutoComplete.getPlace();

          //verify result
          if (pickupPlace.geometry === undefined || pickupPlace.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = pickupPlace.geometry.location.lat();
          this.longitude = pickupPlace.geometry.location.lng();
          this.getFormattedAddress(this.latitude, this.longitude);
          this.zoom = 12;
        });
      });

    });
  }

  addBusinessValidation() {
    this.formGroupAddBusiness = new FormGroup({
      registeredName: new FormControl('', [
        Validators.required
      ]),
      tradingName: new FormControl('', [
        Validators.required
      ]),
      registrationNumber: new FormControl('', [
        Validators.required
      ]),
      /*vatNumber: new FormControl('', [
        Validators.required
      ]),*/
      contactNumber: new FormControl('', [
        Validators.required
      ]),
      businessUsername: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
      businessPassword: new FormControl('', [
        Validators.required
      ]),
      companySummary: new FormControl('', [
        Validators.required
      ])
    });
  }

  addSubcontractorValidation() {
    this.formGroupAddBusiness = new FormGroup({
      registeredName: new FormControl('', [
        Validators.required
      ]),
      tradingName: new FormControl('', [
        Validators.required
      ]),
      registrationNumber: new FormControl('', [
        Validators.required
      ]),
      /*vatNumber: new FormControl('', [
        Validators.required
      ]),*/
      contactNumber: new FormControl('', [
        Validators.required
      ]),
      businessUsername: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
      businessPassword: new FormControl('', [
        Validators.required
      ]),
      servicesSummary: new FormControl('', [
        Validators.required
      ]),
      gitInsurance: new FormControl('', [
        Validators.required
      ])
    });
  }

  validateNoDuplicateUserName() {

    let userName = (<HTMLInputElement> document.getElementById("businessUsername")).value;
    if(!this.regEx.test(userName)) {
      // todo - we validate if there is a duplicate
    } else {

    }
  }

  /**
   * Adds a business to the system
   */
  registerBusiness() {

    if (this.entityType === 'CLIENT') {
      this.registerClient();
    } else if (this.entityType === 'SUBCONTRACTOR') {
      this.registerSubContractor();
    } else {
      // todo - raise some sort of exception
    }
  }

  registerClient() {
    this.httpClient.post(LOGIN_URL + "/registrationResource/registerClient",
      {
        "registeredName": (<HTMLInputElement> document.getElementById("registeredName")).value,
        "tradingName": (<HTMLInputElement> document.getElementById("tradingName")).value,
        "vatNumber": (<HTMLInputElement> document.getElementById("vatNumber")).value,
        "registrationNumber": (<HTMLInputElement> document.getElementById("registrationNumber")).value,
        "longitude": this.longitude,
        "latitude": this.latitude,
        "contactNumber": (<HTMLInputElement> document.getElementById("contactNumber")).value,
        "userName": (<HTMLInputElement> document.getElementById("businessUsername")).value,
        "password": (<HTMLInputElement> document.getElementById("businessPassword")).value,
        "companySummary": (<HTMLInputElement> document.getElementById("companySummary")).value
      })
      .subscribe(
        data => {
          this.dataResults = data;
          if (this.dataResults.success === true && this.dataResults.httpStatus === "OK") {
            this.resultMessage = this.dataResults.message;
            $('#resultModal').modal('show');
          } else {
            this.resultMessage = this.dataResults.message;
            $('#errorModal').modal('show');
          }
        },
        error => {
          this.resultMessage = "An error occurred. Please contact support to assist you";
          $('#errorModal').modal('show');
        }
      );
  }

  registerSubContractor() {
    this.httpClient.post(LOGIN_URL + "/registrationResource/registerSubcontractor",
      {
        "businessId": "",
        "businessAdminId": "",
        "consultantId": "",
        "registeredName": (<HTMLInputElement> document.getElementById("registeredName")).value,
        "tradingName": (<HTMLInputElement> document.getElementById("tradingName")).value,
        "vatNumber": (<HTMLInputElement> document.getElementById("vatNumber")).value,
        "registrationNumber": (<HTMLInputElement> document.getElementById("registrationNumber")).value,
        "longitude": this.longitude,
        "latitude" : this.latitude,
        "contactNumber": (<HTMLInputElement> document.getElementById("contactNumber")).value,
        "logo" : null,
        "userName": (<HTMLInputElement> document.getElementById("businessUsername")).value,
        "password": (<HTMLInputElement> document.getElementById("businessPassword")).value,
        "servicesSummary": (<HTMLInputElement> document.getElementById("servicesSummary")).value,
        "gitInsuranceSummary": (<HTMLInputElement> document.getElementById("gitInsurance")).value
      })
      .subscribe(
        data  => {
          this.dataResults = data;
          if (this.dataResults.success === true && this.dataResults.httpStatus === "OK") {
            this.resultMessage = this.dataResults.message;
            $('#resultModal').modal('show');
          } else {
            this.resultMessage = this.dataResults.message;
            $('#errorModal').modal('show');
          }
        },
        error  => {
          this.resultMessage = "An error occurred. Please contact support to assist you";
          $('#errorModal').modal('show');
        }
      );

  };


  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;

        this.zoom = 8;
        this.getFormattedAddress(this.latitude, this.longitude);
      });
    }
  }

  pickupMarkerDragEnd($event: MouseEvent) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getFormattedAddress(this.latitude, this.longitude);
  }

  redirectToLogin() {
    window.location.href = "/login"
  }


  getFormattedAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.location = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }
}
