import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OpportunitiesComponent} from "./opportunities.component";

@NgModule({
  declarations: [OpportunitiesComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    OpportunitiesComponent
  ]
})
export class OpportunitiesModule{
}
