import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AddTruckComponent} from "./add-truck.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@NgModule({
  declarations: [AddTruckComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    AddTruckComponent
  ]
})
export class AddTruckModule{
}
