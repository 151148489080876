import {Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import {MapsAPILoader, MouseEvent } from '@agm/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DomSanitizer} from '@angular/platform-browser';
import {DatePipe} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {google} from '@google/maps';
import { BASE_URL } from '../config/globals';

declare var $: any;
declare var google;

@Component({
  selector: 'app-addLoad',
  templateUrl: './addLoad.component.html',
  styleUrls: ['./addLoad.component.scss']
})
export class AddLoadComponent implements OnInit {

  pickupLatitude;
  pickupLongitude;

  dropOffLatitude;
  dropOffLongitude;

  zoom;

  loadTime;

  resultButtonText;
  resultMessage;
  addSuccess = false;

  public searchControl: FormControl;
  @ViewChild("pickupSearch")
  public pickupSearchElementRef: ElementRef;

  @ViewChild("dropOffSearch")
  public dropOffSearchElementRef: ElementRef;

  address;
  geoCoder;
  pickup='';
  dropOff='';

  userRole;
  entityId;

  consultantId;
  subcontrollerId;
  subcontractorId;

  availableTrucksForLoad: any={};

  pickupGpsCoordinates = '';
  dropOffGpsCoordinates = '';
  month = '';
  year = '';
  formGroupAddLoad: FormGroup;
  dataResults: any={};
  defaultValue = "UNASSIGNED";
  defaultTrailer = 'UNASSIGNED';

  errorText;

  constructor(private httpClient: HttpClient, public sanitizer: DomSanitizer,
              private datePipe: DatePipe, private activatedRoute: ActivatedRoute,
              private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone) {}

  ngOnInit() {

    if (sessionStorage.getItem("userRole") === null || sessionStorage.getItem("userRole") === '' ||
      sessionStorage.getItem("entityId") === null || sessionStorage.getItem("entityId") === '') {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.userRole = sessionStorage.getItem("userRole");

    if (this.userRole !== null && this.userRole === 'SUBCONTRACTOR') {
      this.subcontractorId = sessionStorage.getItem("entityId");

    } else if (this.userRole !== null && this.userRole === 'SUBCONTROLLER') {
      this.subcontrollerId = sessionStorage.getItem("entityId");

    } else if (this.userRole !== null && this.userRole === 'CONSULTANT') {
      this.consultantId = sessionStorage.getItem("entityId");
    }

    this.entityId = sessionStorage.getItem("entityId");

    // Note, a small hack, if the value is null, it means we redirecting through the application
    /*this.activatedRoute.queryParams.subscribe(params => {
      if (params['consultantId'] != null) {
        this.consultantId = params['consultantId'];
      } else if (params['subcontrollerId'] != null) {
        this.subcontrollerId = params['subcontrollerId'];
      }
    });*/

    //create search FormControl
    this.searchControl = new FormControl();
    this.setCurrentLocation();


    this.mapsAPILoader.load().then(() => {

      this.geoCoder = new google.maps.Geocoder();

      let loadLocationAutoComplete = new google.maps.places.Autocomplete(this.pickupSearchElementRef.nativeElement);
      let dropoffLocationAutoComplete = new google.maps.places.Autocomplete(this.dropOffSearchElementRef.nativeElement);

      loadLocationAutoComplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let pickupPlace: google.maps.places.PlaceResult = loadLocationAutoComplete.getPlace();

          //verify result
          if (pickupPlace.geometry === undefined || pickupPlace.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.pickupLatitude = pickupPlace.geometry.location.lat();
          this.pickupLongitude = pickupPlace.geometry.location.lng();
          this.getPickupFormattedAddress(this.pickupLatitude, this.pickupLongitude);
          this.zoom = 12;
        });
      });

      dropoffLocationAutoComplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let dropoffPlace: google.maps.places.PlaceResult = dropoffLocationAutoComplete.getPlace();

          //verify result
          if (dropoffPlace.geometry === undefined || dropoffPlace.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.dropOffLatitude = dropoffPlace.geometry.location.lat();
          this.dropOffLongitude = dropoffPlace.geometry.location.lng();

          this.getDropoffFormattedAddress(this.dropOffLatitude, this.dropOffLongitude);

          this.zoom = 12;
        });
      });
    });

    if (this.userRole === 'CONSULTANT') {
      this.validationConsultantLoad();
    } else if (this.userRole === 'SUBCONTROLLER' || this.userRole === 'SUBCONTRACTOR') {
      this.validationSubcontractorLoad();
    } else {
      window.location.href = "/403?message=Invalid session, please login again.";
    }
  }

  complete() {
    this.redirect();
  }

  redirect() {

    if (this.userRole === 'BUSINESS') {
      window.location.href = "/business?id=" + this.entityId;
    } else if (this.userRole === 'BUSINESSADMIN') {
      window.location.href = "/business-admin?id=" + this.entityId;
    } else if (this.userRole === 'CONSULTANT') {
      window.location.href = "/consultant?consultantId=" + this.entityId;
    } else if (this.userRole === 'SUBCONTROLLER') {
      window.location.href = "/sub-controller";
    } else if (this.userRole === 'SUBCONTRACTOR') {
      window.location.href = "/sub-contractor?id=" + this.entityId;
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.pickupLatitude = position.coords.latitude;
        this.pickupLongitude = position.coords.longitude;

        this.dropOffLatitude = position.coords.latitude;
        this.dropOffLongitude = position.coords.longitude;

        this.zoom = 8;
        this.getPickupFormattedAddress(this.pickupLatitude, this.pickupLongitude);
        this.getDropoffFormattedAddress(this.dropOffLatitude, this.dropOffLongitude);
      });
    }
  }

  pickupMarkerDragEnd($event: MouseEvent) {
    console.log($event);
    this.pickupLatitude = $event.coords.lat;
    this.pickupLongitude = $event.coords.lng;
    this.getPickupFormattedAddress(this.pickupLatitude, this.pickupLongitude);
  }

  dropOffMarkerDragEnd($event: MouseEvent) {
    console.log($event);
    this.dropOffLatitude = $event.coords.lat;
    this.dropOffLongitude = $event.coords.lng;
    this.getDropoffFormattedAddress(this.pickupLatitude, this.pickupLongitude);
  }

  getPickupFormattedAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.pickup = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  getDropoffFormattedAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.dropOff = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  saveLoad() {
    this.addLoad();
  }

  cancel() {
    this.redirect();
  }

  validationSubcontractorLoad() {
    //validation Load
    this.formGroupAddLoad = new FormGroup({
      loadNumber: new FormControl('', [
        Validators.required
      ]),
      loadTime: new FormControl('', [
        Validators.required
      ]),
      clientName: new FormControl('', [
        Validators.required
      ]),
      /*loadStatus: new FormControl('', [
        Validators.required
      ]),*/
      loadRate: new FormControl('', [
        Validators.required
      ]),
      trailerType: new FormControl('', [
        Validators.required
      ]),
      assignTo: new FormControl('', [
        Validators.required
      ])
      /*loadPickupLocation: new FormControl('', [
        Validators.required
      ]),
      loadDropOffLocation: new FormControl('', [
        Validators.required
      ]),*/
    });
  }

  validationConsultantLoad() {
    //validation Load
    this.formGroupAddLoad = new FormGroup({
      loadNumber: new FormControl('', [
        Validators.required
      ]),
      loadTime: new FormControl('', [
        Validators.required
      ]),
      clientName: new FormControl('', [
        Validators.required
      ]),
      /*loadStatus: new FormControl('', [
        Validators.required
      ]),*/
      loadRate: new FormControl('', [
        Validators.required
      ]),
      trailerType: new FormControl('', [
        Validators.required
      ])
      /*loadPickupLocation: new FormControl('', [
        Validators.required
      ]),
      loadDropOffLocation: new FormControl('', [
        Validators.required
      ]),*/
    });
  }

  findAvailableTrucksMatchingTrailer() {

      let trailerType = (<HTMLInputElement> document.getElementById("trailerType")).value;

      const params = new HttpParams().set('entityId', this.entityId).set("userRole", this.userRole).set("trailerType", trailerType);
      this.httpClient.get(BASE_URL + '/truckResource/getAvailableTrucksWithTrailerSpecified', {params}).subscribe((res) => {
        //console.log(res);
        this.availableTrucksForLoad = res;
        $('#assignTo').removeAttr("disabled");
      });
  }

  addLoad(){

    if (this.pickup === null || this.pickup === '') {
      this.errorText = "Please insert a pickup location";
      $('#errorModal').modal('show');

    } else if (this.dropOff === null || this.dropOff === '') {
      this.errorText = "Please insert a dropoff location";
      $('#errorModal').modal('show');

    } else {

      let truckId = '';
      let loadStatus = 'UNASSIGNED';
      let tempConsultantId = this.consultantId;
      let tempSubcontractorId = '';
      let tempSubcontrollerId = '';

      if (this.userRole === 'SUBCONTROLLER') {
        truckId = (<HTMLInputElement> document.getElementById("assignTo")).value;
        loadStatus = 'ASSIGNED';
        tempConsultantId = '';
        tempSubcontrollerId = this.entityId;
      }

      if ((<HTMLInputElement> document.getElementById("loadStatus")) !== null && this.userRole !== 'SUBCONTRACTOR' && this.userRole !== 'SUBCONTROLLER') {
        loadStatus = (<HTMLInputElement> document.getElementById("loadStatus")).value;
      }

      this.httpClient.post(BASE_URL + "/loadResource/addLoad",
        {
          "businessId": "",
          "businessAdminId": "",
          "consultantId": tempConsultantId,
          "loadNumber": (<HTMLInputElement> document.getElementById("loadNumber")).value,
          "loadingTime": (<HTMLInputElement> document.getElementById("loadTime")).value,
          "clientName": (<HTMLInputElement> document.getElementById("clientName")).value,
          "loadPickupLocation": this.pickup,
          "loadDropOffLocation" : this.dropOff,
          "pickupGpsCoordinates" : this.pickupLatitude + "|" + this.pickupLongitude,
          "dropOffGpsCoordinates" : this.dropOffLatitude + "|" + this.dropOffLongitude,
          "loadStatus": loadStatus,
          "loadRate": (<HTMLInputElement> document.getElementById("loadRate")).value,
          "loadAllocation": {
            "truckId": truckId,
            "subcontrollerId": tempSubcontrollerId
          },
        })
        .subscribe(
          data  => {
            this.dataResults = data;
            if (this.dataResults.success === true && this.dataResults.httpStatus === "OK") {
              this.resultMessage = "Load successfully added.";
              this.resultButtonText = "Continue";
              this.addSuccess = true;
              $('#resultModal').modal('show');
            } else {
              this.resultMessage = this.dataResults.message;
              this.resultButtonText = "Continue";

              this.addSuccess = false;
              $('#resultModal').modal('show');
            }
          },
          error  => {
            this.resultMessage = this.dataResults.message;
            this.resultButtonText = "Continue";

            this.addSuccess = false;
            $('#resultModal').modal('show');
          }
        );
    }


  }
}
