import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {HttpParams} from  "@angular/common/http";
import {DatePipe} from '@angular/common';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { BASE_URL } from '../config/globals';


declare var $: any;

@Component({
  selector: 'app-sub-controller',
  templateUrl: './manage-sub-controller.component.html',
  styleUrls: ['./manage-sub-controller.component.scss']
})
export class ManageSubControllerComponent implements OnInit {

  data: any={};
  subcontrollers;
  dataResults: any={};
  response;

  private subcontractorId;
  month = '';
  year = '';

  userRole;
  entityId;

  subcontrollerTrucks = null;
  subcontrollerDrivers = null;

  tempSubcontrollerId;
  tempSubcontrollerName;
  tempSubcontrollerSurname;

  resultMessage;
  confirmText;
  confirmType;

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private datePipe: DatePipe) {
  }

  ngOnInit() {

    if (sessionStorage.getItem("entityId") !== null && sessionStorage.getItem("entityId") !== '') {
      this.subcontractorId = sessionStorage.getItem("entityId");
      this.entityId = sessionStorage.getItem("entityId");
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.userRole = sessionStorage.getItem("userRole");

    if (this.userRole === null || this.userRole !== 'SUBCONTRACTOR') {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.getSubcontrollers();
  }


  confirmAddSubcontroller() {
    this.confirmType = 'ADD-SUBCONTROLLER';
    this.confirmText = 'Confirm adding of a new controller?';
    $('#confirmModal').modal('show');
  }

  getSubcontrollers() {

    const params = new HttpParams().set('subcontractorId', this.entityId);

    this.httpClient.get(BASE_URL + '/subcontractorResource/getSubcontrollersLinkedToSubcontractor',{params}).subscribe((res)=>{
      this.subcontrollers = res;
    });
  }

  removeController() {
    this.resultMessage = 'Under construction - removing controller.';
    $('#resultModal').modal('show');
  }

  manageTruckAllocations(subcontrollerId, name, surname) {

    this.tempSubcontrollerId = subcontrollerId;
    this.tempSubcontrollerName = name;
    this.tempSubcontrollerSurname = surname;

    this.getTrucksLinkedToSubcontroller();
    $('#truckManagement').modal('show');
  }

  allocateTruckToNewSubcontroller(selectId, truckId) {

    let assignedSubcontrollerId = (<HTMLInputElement> document.getElementById(selectId)).value;

    this.httpClient.put(BASE_URL + "/subcontractorResource/allocateTruckToNewSubcontroller?truckId=" + truckId + "&subcontrollerId=" + assignedSubcontrollerId,

      {
        "truckId": truckId,
        "subcontrollerId": assignedSubcontrollerId
      })

      .subscribe(
        data => {
          this.response = data;
          if (this.response.success === true && this.response.httpStatus === "OK") {
            this.resultMessage = this.response.message;
            this.getTrucksLinkedToSubcontroller();
            $('#resultModal').modal('show');
          } else {
            this.resultMessage = 'An error occurred with reallocation. Please contact support';
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'An error occurred with reallocation. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }

  allocateDriverToNewSubcontroller(driverId) {

    let assignedSubcontrollerId = (<HTMLInputElement> document.getElementById(driverId)).value;

    this.httpClient.put(BASE_URL + "/subcontractorResource/allocateDriverToNewSubcontroller?driverId=" + driverId + "&subcontrollerId=" + assignedSubcontrollerId,

      {
        "driverId": driverId,
        "subcontrollerId": assignedSubcontrollerId
      })

      .subscribe(
        data => {
          this.response = data;
          if (this.response.success === true && this.response.httpStatus === "OK") {
            this.resultMessage = this.response.message;
            this.getDriversLinkedToSubcontrollerWithoutATruck();
            $('#resultModal').modal('show');
          } else {
            this.resultMessage = 'An error occurred with reallocation. Please contact support';
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'An error occurred with reallocation. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }

  getTrucksLinkedToSubcontroller() {

    const params = new HttpParams().set('subControllerId', this.tempSubcontrollerId);

    this.httpClient.get(BASE_URL + '/subControllerResource/getAllTrucksForSubcontroller',{params}).subscribe((res)=>{
      //console.log(res);
      this.subcontrollerTrucks = res;
    });
  }

  getDriversLinkedToSubcontrollerWithoutATruck() {

    const params = new HttpParams().set('subcontrollerId', this.tempSubcontrollerId);

    this.httpClient.get(BASE_URL + '/subControllerResource/getDriversNotLinkedToTrucks',{params}).subscribe((res)=>{
      //console.log(res);
      this.subcontrollerDrivers = res;
    });
  }

  manageDriverAllocations(subcontrollerId, name, surname) {
    this.tempSubcontrollerId = subcontrollerId;
    this.tempSubcontrollerName = name;
    this.tempSubcontrollerSurname = surname;

    this.getDriversLinkedToSubcontrollerWithoutATruck();
    $('#driverManagement').modal('show');

  }

  complete() {
    this.redirect();
  }

  cancel() {
    this.redirect();
  }

  redirect() {

    if (this.userRole === 'BUSINESS') {
      window.location.href = "/business?id=" + this.entityId;
    } else if (this.userRole === 'BUSINESSADMIN') {
      window.location.href = "/business-admin?id=" + this.entityId;
    } else if (this.userRole === 'CONSULTANT') {
      window.location.href = "/consultant?consultantId=" + this.entityId;
    } else if (this.userRole === 'SUBCONTROLLER') {
      window.location.href = "/sub-controller";
    } else if (this.userRole === 'SUBCONTRACTOR') {
      window.location.href = "/sub-contractor?id=" + this.entityId;
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }
  }
}
