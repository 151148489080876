import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BASE_URL, LOGIN_URL} from '../config/globals';
import {ActivatedRoute} from "@angular/router";

declare var $: any;

/**
 *
 * @author Megan
 */

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  dataResults: any={};
  authResponse: any={};

  token;

  formGroupUpdatePassword: FormGroup;
  formSendEmails: FormGroup;

  entityId;
  resultMessage;


  constructor(private activatedRoute: ActivatedRoute, private httpClient: HttpClient) {
  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['token'] != null && params['token'] !== '') {
        this.token = params['token'];

        if (this.token !== 'tyzg') {
          window.location.href = "/403?message=You are not authorized to view this page";
        }
      } else {
        window.location.href = "/403?message=You are not authorized to view this page";
      }
    });

    this.invitationListForm();
    this.updatePasswordValidation();
  }

  updatePasswordValidation() {
    this.formGroupUpdatePassword = new FormGroup({
      inputPassword: new FormControl('', [
        Validators.required
      ])
    });
  }



  invitationListForm(){
    this.formSendEmails = new FormGroup({
      invitationList: new FormControl('', [
        Validators.required
      ])
    });
  }



  updatePassword() {
    let userName = (<HTMLInputElement> document.getElementById("staticEmail")).value;
    let password = (<HTMLInputElement> document.getElementById("inputPassword")).value;

    this.httpClient.put(LOGIN_URL + "/admin/updateUserPassword?userName=" + userName + "&password=" + password,

      {
        "userName": (<HTMLInputElement> document.getElementById("staticEmail")).value,
        "password": (<HTMLInputElement> document.getElementById("inputPassword")).value
      })

      .subscribe(
        data => {
          this.dataResults = data;
          if (this.dataResults.success === true && this.dataResults.httpStatus === "OK") {
            $('#myAlert1').removeClass('hidden').addClass('show');
          } else {
            $('#myAlert2').removeClass('hidden').addClass('show');
            console.log("Error", data);
          }
        },
        error => {
          $('#myAlert2').removeClass('hidden').addClass('show');
          console.log("Error", error);
        }
      );
  }

  sendInvitationEmails() {

    let commaTokenizedEmailList = (<HTMLInputElement>document.getElementById("invitationList")).value;

    this.httpClient.post(LOGIN_URL + "/admin/sendEmailInvites",
      {
        "commaTokenizedEmailList":commaTokenizedEmailList
      })
      .subscribe(
        data  => {
          this.dataResults = data;
          if (this.dataResults.success === true && this.dataResults.httpStatus === "OK") {
            this.resultMessage = 'Mails were sent';
            $('#resultModal').modal('show');
          } else {
            this.resultMessage = 'An error occurred sending the mails';
            $('#resultModal').modal('show');
          }
        },
        error  => {
          this.resultMessage = 'An error occurred sending the mails';
          $('#resultModal').modal('show');
        }
      );
  }

}
