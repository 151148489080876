import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpParams} from "@angular/common/http";
import { BASE_URL } from '../config/globals';

declare var $: any;

@Component({
  selector: 'opportunity-management',
  templateUrl: './opportunity-management.component.html',
  styleUrls: ['./opportunity-management.component.scss']
})
export class OpportunityManagementComponent implements OnInit {

  activeOpportunities: any={};
  response: any={};
  consultants: any={};

  tempBusinessToSubcontractorRequirementId;

  entityId;
  userRole;

  resultMessage;
  confirmText;

  constructor(private httpClient: HttpClient, private datePipe: DatePipe, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {

    if (sessionStorage.getItem("entityId") !== null && sessionStorage.getItem("entityId") !== '') {
      this.entityId = sessionStorage.getItem("entityId");
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.userRole = sessionStorage.getItem("userRole");
    this.getActiveOpportunities();
  }

  getActiveOpportunities() {
    const params = new HttpParams().set('entityId', this.entityId).set('userRole', this.userRole).set('activeOnly', 'true');
    this.httpClient.get(BASE_URL + '/businessResource/getBusinessOpportunities', {params}).subscribe((res) => {
        this.activeOpportunities = res;
    });
  }



  confirmRemoveOpportunity(businessToSubcontractorRequirementId) {

    this.tempBusinessToSubcontractorRequirementId = businessToSubcontractorRequirementId;
    this.confirmText = 'Confirm removal of opportunity?';
    $('#confirmModal').modal('show');
  }


  actionRemoval() {

    this.httpClient.put(BASE_URL + "/businessResource/removeOpportunity?businessToSubcontractorRequirementId=" + this.tempBusinessToSubcontractorRequirementId + "&entityId=" + this.entityId + "&userRole=" + this.userRole,
      {"businessToSubcontractorRequirementId" : this.tempBusinessToSubcontractorRequirementId,
              "entityId": this.entityId,
              "userRole": this.userRole
      })
      .subscribe(
        data => {
          this.response = data;
          if (this.response.success === true && this.response.httpStatus === "OK") {

            this.resultMessage = this.response.message;
            $('#resultModal').modal('show');

            this.getActiveOpportunities();
          } else {

            this.resultMessage = this.response.message;
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'Error occurred removing the opportunity. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }
}
