import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpParams} from "@angular/common/http";
import { BASE_URL } from '../config/globals';

declare var $: any;

@Component({
  selector: 'app-sub-contractor',
  templateUrl: './sub-contractor.component.html',
  styleUrls: ['./sub-contractor.component.scss']
})
export class SubContractorComponent implements OnInit {

  data: any={};
  results: any={};
  trucks: any={};
  month = '';
  year = '';
  private subcontractorId;

  confirmType;
  confirmText;

  entityKey;
  userRole;
  resultMessage;

  tempSubcontrollerId;

  formGroupAddDriver: FormGroup;
  formGroupAllocateLoad: FormGroup;

  dataResults:any={};

  constructor(private httpClient: HttpClient, private datePipe: DatePipe, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['id'] != null) {
        this.subcontractorId = params['id'];
      }
    });

    this.entityKey = sessionStorage.getItem("entityKey");
    this.userRole = sessionStorage.getItem("userRole");

    this.month = this.datePipe.transform(new Date(), "MMMM");
    this.year = this.datePipe.transform(new Date(), "yyyy");
    this.getSubcontractorDashboardInfo();
    this.getAvailableLoads();

    this.validationDriver();
    this.validationLoadAllocation();
  }

  getSubcontractorDashboardInfo() {
    const params = new HttpParams().set('subcontractorId', this.subcontractorId);
    this.httpClient.get(BASE_URL + '/subcontractorResource/getSubcontractorDashboardInfo', {params}).subscribe((res) => {
      this.data = res;
    });
  }

  validationDriver() {
    this.formGroupAddDriver = new FormGroup({
      driverName: new FormControl('', [
        Validators.required
      ]),
      driverSurname: new FormControl('', [
        Validators.required
      ]),
      driverNationality: new FormControl('', [
        Validators.required
      ]),
      driverIdentificationNumber: new FormControl('', [
        Validators.required
      ]),
      driverEmailAddress: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
      driverContactNumber: new FormControl('', [
        Validators.required
      ]),
      driverEntityStatus: new FormControl('', [
        Validators.required
      ]),
      driverSubcontroller: new FormControl('', [
        Validators.required
      ]),
      driverUsername: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
      driverPassword: new FormControl('', [
        Validators.required
      ])
    });
  }

  add_Driver() {
    this.httpClient.post(BASE_URL + "/driverResource/addDriver",
      {
        "name": (<HTMLInputElement> document.getElementById("driverName")).value,
        "surname": (<HTMLInputElement> document.getElementById("driverSurname")).value,
        "nationality": (<HTMLInputElement> document.getElementById("driverNationality")).value,
        "identificationNumber": (<HTMLInputElement> document.getElementById("driverIdentificationNumber")).value,
        "email": (<HTMLInputElement> document.getElementById("driverEmailAddress")).value,
        "subcontrollerId": (<HTMLInputElement> document.getElementById("driverSubcontroller")).value, //TODO
        "subContractorId": this.data.subcontractorId,
        "truckId": "",
        "contactNumber": (<HTMLInputElement> document.getElementById("driverContactNumber")).value,
        "entityStatus": (<HTMLInputElement> document.getElementById("driverEntityStatus")).value,
        "userName": (<HTMLInputElement> document.getElementById("driverUsername")).value,
        "password": (<HTMLInputElement> document.getElementById("driverSurname")).value
      })
      .subscribe(
        data => {
          this.dataResults = data;
          if (this.dataResults.success === true && this.dataResults.httpStatus === "OK") {
            console.log("POST Request is successful ", data);
            $('#myAlert7').removeClass('hidden').addClass('show');
          } else {
            $('#myAlert8').removeClass('hidden').addClass('show');
            console.log("Error", data);
          }
        },
        error => {
          console.log("Error", error);
          $('#myAlert8').removeClass('hidden').addClass('show');
        }
      );
    $('#driverModal').modal('hide');
    $('#driverModal').on('hidden.bs.modal', function () {
      $(this).find('form').trigger('reset');
    })
  }

  getAvailableLoads(){
    //Get the loads available to the subcontractor
    const params = new HttpParams().set('subcontractorId', this.subcontractorId).set("subcontrollerId", "");
    this.httpClient.get(BASE_URL + '/loadResource/getAvailableLoadsList', {params}).subscribe((res)=>{
      this.results = res;
    });
  }

  validationLoadAllocation() {
    this.formGroupAllocateLoad = new FormGroup({
      loadController: new FormControl('', [
        Validators.required
      ]),
      loadDriver: new FormControl('', [
        Validators.required
      ]),
      loadTruck: new FormControl('', [
        Validators.required
      ])
    });
  }

  allocateLoad(load, loadNum){
    this.httpClient.post(BASE_URL + "/loadAllocationResource/assignLoad",
      {
        "loadId": load,
        "loadNumber": loadNum,
        "subcontrollerId": (<HTMLInputElement> document.getElementById("loadController")).value,
        "subcontractorId": this.data.subcontractorId,
        "truckId":  (<HTMLInputElement> document.getElementById("loadTruck")).value,
        "driverId": "",
      })
      .subscribe(
        data => {
          this.dataResults = data;
          if (this.dataResults.success === true && this.dataResults.httpStatus === "OK") {
            console.log("POST Request is successful ", data);
            $('#myAlert9').removeClass('hidden').addClass('show');
          } else {
            $('#myAlert10').removeClass('hidden').addClass('show');
            console.log("Error", data);
          }
        },
        error => {
          console.log("Error", error);
          $('#myAlert10').removeClass('hidden').addClass('show');
        }
      );
    window.location.reload();
  }

  complete() {

  }

  confirmDeactivateController(subcontrollerId) {
    this.tempSubcontrollerId = subcontrollerId;
    this.confirmType = 'DEACTIVATE-CONTROLLER';
    this.confirmText = 'Confirm deactivation of controller?';
    $('#confirmModal').modal('show');
  }

  confirmActivateController(subcontrollerId) {
    this.tempSubcontrollerId = subcontrollerId;
    this.confirmType = 'ACTIVATE-CONTROLLER';
    this.confirmText = 'Confirm activation of controller?';
    $('#confirmModal').modal('show');
  }

  deactivateController() {
    this.resultMessage = 'Under construction';
    $('#resultModal').modal('show');
  }

  activateController() {
    this.resultMessage = 'Under construction';
    $('#resultModal').modal('show');
  }
}
