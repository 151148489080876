import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {DropoffLocationComponent} from "./dropoff-location.component";

@NgModule({
  declarations: [DropoffLocationComponent],
  imports: [
    CommonModule
  ],
  exports: [
    DropoffLocationComponent
  ]
})
export class DropoffLocationModule{
}
