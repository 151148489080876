import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { BASE_URL } from '../config/globals';


@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.scss']
})
export class DriverComponent implements OnInit {

  data: any={};

  constructor(private httpClient: HttpClient) { }

  ngOnInit() {
    this.get_driverResults();
  }

  get_driverResults(){
    this.httpClient.get(BASE_URL + '/driverResource/getDriverInfo?driverId=cb813c20-cc67-4e40-9c81-97f875c5fa73').subscribe((res)=>{
      //console.log(res);
      this.data = res;
    });
  }

}
