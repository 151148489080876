import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AvailableContractsComponent} from "./available-contracts.component";

@NgModule({
  declarations: [AvailableContractsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    AvailableContractsComponent
  ]
})
export class AvailableContractsModule{
}
