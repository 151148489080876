import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpParams} from "@angular/common/http";
import { BASE_URL } from '../config/globals';

declare var $: any;

@Component({
  selector: 'view-app-sub-contractor',
  templateUrl: './sub-contractor-profile.component.html',
  styleUrls: ['./sub-contractor-profile.component.scss']
})
export class SubcontractorProfileComponent implements OnInit {

  subcontractorInfo: any={};
  trucks: any={};
  trailers: any={};

  month = '';
  year = '';
  subcontractorId;
  userRole;

  constructor(private httpClient: HttpClient, private datePipe: DatePipe, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['id'] != null) {
        this.subcontractorId = params['id'];
      }
    });

    this.userRole = sessionStorage.getItem("userRole");
    this.getSubcontractorInfo();
    this.getTruckLinkedToSubcontractor();
    this.getTrailersLinkedToSubcontractor();

  }

  getSubcontractorInfo() {
    const params = new HttpParams().set('subcontractorId', this.subcontractorId);
    this.httpClient.get(BASE_URL + '/subcontractorResource/getSubcontractorInfo', {params}).subscribe((res) => {
      this.subcontractorInfo = res;
    });
  }

  getTruckLinkedToSubcontractor() {
    const params = new HttpParams().set('subcontractorId', this.subcontractorId);
    this.httpClient.get(BASE_URL + '/subcontractorResource/getTrucksLinkedToSubcontractor', {params}).subscribe((res) => {
      this.trucks = res;
    });
  }

  getTrailersLinkedToSubcontractor() {
    const params = new HttpParams().set('subcontractorId', this.subcontractorId);
    this.httpClient.get(BASE_URL + '/subcontractorResource/getTrailersLinkedToSubcontractor', {params}).subscribe((res) => {
      this.trailers = res;
    });
  }
}
