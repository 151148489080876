import {NgModule} from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AgmCoreModule} from '@agm/core';
import {CommonModule} from "@angular/common";
import {UpdateContractComponent} from "./update-contract.component";


@NgModule({
  declarations: [UpdateContractComponent],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAR8J9QbcE44i7DJN2vcBaDXzHK52Llf9s',
      libraries: ["places"]
    })
  ],
  exports: [
    UpdateContractComponent
  ]
})
export class UpdateContractModule{
}
