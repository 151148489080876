import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ForgotPasswordComponent} from "./forgot-password.component";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [ForgotPasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    ForgotPasswordComponent
  ]
})
export class ForgotPasswordModule{
}
