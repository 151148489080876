import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {LoginModule} from './login/login.module';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ForgotPasswordModule} from './forgot-password/forgot-password.module';
import {BusinessComponent} from './business/business.component';
import {BusinessModule} from './business/business.module';
import {SubControllerComponent} from './sub-controller/sub-controller.component';
import {SubControllerModule} from './sub-controller/sub-controller.module';
import {SubContractorComponent} from './sub-contractor/sub-contractor.component';
import {SubContractorModule} from './sub-contractor/sub-contractor.module';
import {TruckListComponent} from './truck-list/truck-list.component';
import {TruckListModule} from './truck-list/truck-list.module';
import {TruckComponent} from './truck/truck.component';
import {TruckModule} from './truck/truck.module';
import {DriverListComponent} from './driver-list/driver-list.component';
import {DriverListModule} from './driver-list/driver-list.module';
import {DriverComponent} from './driver/driver.component';
import {DriverModule} from './driver/driver.module';
import {TrailerListComponent} from './trailer-list/trailer-list.component';
import {TrailerListModule} from './trailer-list/trailer-list.module';
import {TrailerComponent} from './trailer/trailer.component';
import {TrailerModule} from './trailer/trailer.module';
import {AdminComponent} from './admin/admin.component';
import {AdminModule} from './admin/admin.module';
import {BusinessAdminComponent} from './business-admin/business-admin.component';
import {BusinessAdminModule} from './business-admin/business-admin.module';
import {ConsultantComponent} from './consultant/consultant.component';
import {ConsultantModule} from './consultant/consultant.module';
import {LoadComponent} from './load/load.component';
import {LoadModule} from './load/load.module';
import {AddLoadModule} from './load/addLoad.module';
import {PickupLocationComponent} from './pickup-location/pickup-location.component';
import {PickupLocationModule} from './pickup-location/pickup-location.module';
import {DropoffLocationComponent} from './dropoff-location/dropoff-location.component';
import {DropoffLocationModule} from './dropoff-location/dropoff-location.module';
import {Error404Component} from './error404/error404.component';
import {Error404Module} from './error404/error404.module';
import {Error403Component} from './error403/error403.component';
import {Error403Module} from "./error403/error403.module";
import {Error401Component} from './error401/error401.component';
import {Error401Module} from "./error401/error401.module";
import {AddLoadComponent} from "./load/addLoad.component";
import {ViewLoadsComponent} from "./load/viewLoads.component";
import {ViewLoadsModule} from "./load/viewLoads.module";
import {ViewSubContractorComponent} from "./sub-contractor/view-sub-contractor.component";
import {ViewSubContractorModule} from "./sub-contractor/view-sub-contractor.module";
import {RegisterBusinessComponent} from './register-business/register-business.component';
import {RegisterBusinessModule} from './register-business/register-business.module';
import {RegisterSubcontractorComponent} from './register-subcontractor/register-subcontractor.component';
import {RegisterSubcontractorModule} from './register-subcontractor/register-subcontractor.module';
import {RegisterChangePasswordComponent} from "./register-business/register-change-password.component";
import {RegisterChangePasswordModule} from "./register-business/register-change-password.module";
import {AddSubControllerComponent} from "./sub-controller/add-sub-controller.component";
import {AddSubControllerModule} from "./sub-controller/add-sub-controller.module";
import {AddTruckComponent} from "./truck/add-truck.component";
import {AddTruckModule} from "./truck/add-truck.module";
import {AddTrailerComponent} from "./trailer/add-trailer.component";
import {AddTrailerModule} from "./trailer/add-trailer.module";
import {AddConsultantComponent} from "./consultant/add-consultant.component";
import {AddConsultantModule} from "./consultant/add-consultant.module";
import {AddBusinessAdminComponent} from "./business-admin/add-business-admin.component";
import {AddBusinessAdminModule} from "./business-admin/add-business-admin.module";
import {ManageConsultantsComponent} from "./client-management/manage-consultants.component";
import {ManageConsultantsModule} from "./client-management/manage-consultants.module";
import {DriverManagementComponent} from "./subcontractor-management/driver.management.component";
import {DriverManagementModule} from "./subcontractor-management/driver.management.module";
import {SubcontractorProfileComponent} from "./sub-contractor/sub-contractor-profile.component";
import {SubContractorProfileModule} from "./sub-contractor/sub-contractor-profile.module";
import {OpportunitiesComponent} from "./opportunities/opportunities.component";
import {OpportunitiesModule} from "./opportunities/opportunities.module";
import {ManageContractorRequestsModule} from "./client-management/manage-contractor-requests.module";
import {ManageContractorRequestsComponent} from "./client-management/manage-contractor-requests.component";
/*import {ManageContractorModule} from "./client-management/contractor-management.module";
import {ManageContractorComponent} from "./client-management/contractor-management.component";*/
import {AddOpportunityComponent} from "./opportunities/add-opportunity.component";
import {AddOpportunityModule} from "./opportunities/add-opportunity.module";
import {OpportunityManagementComponent} from "./opportunities/opportunity-management.component";
import {OpportunityManagementModule} from "./opportunities/opportunity-management.module";
import {AddContractComponent} from "./contracts/addContract.component";
import {AddContractModule} from "./contracts/addContract.module";
import {ContractManagementComponent} from "./contracts/contract-management.component";
import {ContractManagementModule} from "./contracts/contract-management.module";
import {UpdateContractComponent} from "./contracts/update-contract.component";
import {UpdateContractModule} from "./contracts/update-contract.module";
import {AvailableContractsComponent} from "./contracts/available-contracts.component";
import {AvailableContractsModule} from "./contracts/available-contracts.module";
import {ContractRequestsComponent} from "./contracts/contract-requests.component";
import {ContractRequestsModule} from "./contracts/contract-requests.module";
import {ActiveContractsComponent} from "./contracts/active-contracts.component";
import {ActiveContractsModule} from "./contracts/active-contracts.module";
import {TruckManagementComponent} from "./truck/truck-management.component";
import {TruckManagementModule} from "./truck/truck-management.module";
import {ManageSubControllerComponent} from "./sub-controller/manage-sub-controller.component";
import {ManageSubControllerModule} from "./sub-controller/manage-sub-controller.module";
import {ManageBusinessAdminComponent} from "./client-management/manage-business-admin.component";
import {ManageBusinessAdminModule} from "./client-management/manage-business-admin.module";
import {UnderConstructionComponent} from "./temp/under-construction.component";
import {UnderConstructionModule} from "./temp/under-construction.module";
import {EntityActivationComponent} from "./admin/entity-activation.component";
import {EntityActivationModule} from "./admin/entity-activation.module";
import {AdminAuthComponent} from "./admin/admin-auth.component";
import {AdminAuthModule} from "./admin/admin-auth.module";

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent},
  { path: 'reg-password', component: RegisterChangePasswordComponent},
  { path: 'entity-activation', component: EntityActivationComponent},
  { path: 'admin', component: AdminComponent },
  { path: 'admin-auth', component: AdminAuthComponent},
  { path: 'business', component: BusinessComponent },
  { path: 'business-admin', component: BusinessAdminComponent },
  { path: 'add-business-admin', component: AddBusinessAdminComponent },
  { path: 'sub-controller', component: SubControllerComponent },
  { path: 'add-sub-controller', component: AddSubControllerComponent },
  { path: 'sub-contractor', component: SubContractorComponent },
  { path: 'view-sub-contractor', component: ViewSubContractorComponent },
  { path: 'sub-contractor-profile', component: SubcontractorProfileComponent },
  { path: 'opportunities', component: OpportunitiesComponent },
  { path: 'truck-list', component: TruckListComponent },
  { path: 'truck', component: TruckComponent },
  { path: 'add-truck', component: AddTruckComponent },
  { path: 'driver-list', component: DriverListComponent },
  { path: 'driver-management', component: DriverManagementComponent },
  { path: 'driver', component: DriverComponent },
  { path: 'trailer-list', component: TrailerListComponent },
  { path: 'trailer', component: TrailerComponent },
  { path: 'add-trailer', component: AddTrailerComponent },
  { path: 'consultant', component: ConsultantComponent },
  { path: 'manage-consultants', component: ManageConsultantsComponent },
  { path: 'manage-business-admin', component: ManageBusinessAdminComponent },
  { path: 'add-consultant', component: AddConsultantComponent },
  { path: 'under-construction', component: UnderConstructionComponent },
  { path: 'add-opportunity', component: AddOpportunityComponent },
  { path: 'opportunity-management', component: OpportunityManagementComponent },
  { path: 'contract-management', component: ContractManagementComponent },
  { path: 'contract-requests', component: ContractRequestsComponent },
  { path: 'active-contracts', component: ActiveContractsComponent },
  { path: 'update-contract', component: UpdateContractComponent },
  { path: 'available-contracts', component: AvailableContractsComponent },
  { path: 'manage-contractor-requests', component: ManageContractorRequestsComponent }, //
  //{ path: 'contractor-management', component: ManageContractorComponent },
  { path: 'manage-sub-controller', component: ManageSubControllerComponent },
  { path: 'truck-management', component: TruckManagementComponent },
  { path: 'load', component: LoadComponent },
  { path: 'view-loads', component: ViewLoadsComponent },
  { path: 'addLoad', component: AddLoadComponent},
  { path: 'addContract', component: AddContractComponent},
  { path: 'pickup-location', component: PickupLocationComponent },
  { path: 'dropoff-location', component: DropoffLocationComponent },
  { path: 'register-business', component: RegisterBusinessComponent },
  { path: 'register-subcontractor', component: RegisterSubcontractorComponent },
  { path: '401', component: Error401Component, pathMatch: 'full' },
  { path: '403', component: Error403Component, pathMatch: 'full'},
  { path: '404', component: Error404Component, pathMatch: 'full'},
  { path: '**', redirectTo: '/404', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes), LoginModule, ForgotPasswordModule, AdminModule, BusinessAdminModule,
    BusinessModule, SubControllerModule, SubContractorModule, TruckListModule,
    TruckModule, DriverListModule, DriverModule, TrailerListModule, TrailerModule,
    ConsultantModule, LoadModule, ViewLoadsModule, PickupLocationModule, DropoffLocationModule, Error404Module,
    Error403Module, Error401Module, AddLoadModule, ViewSubContractorModule, RegisterBusinessModule, RegisterSubcontractorModule,
    RegisterChangePasswordModule, AddSubControllerModule, AddTruckModule, AddTrailerModule, AddConsultantModule, AddBusinessAdminModule,
    ManageConsultantsModule, DriverManagementModule, SubContractorProfileModule, OpportunitiesModule, ManageContractorRequestsModule,
    AddOpportunityModule, OpportunityManagementModule, AddContractModule, ContractManagementModule, UpdateContractModule,
    AvailableContractsModule, ContractRequestsModule, ActiveContractsModule, TruckManagementModule, ManageSubControllerModule, ManageBusinessAdminModule,
    UnderConstructionModule, EntityActivationModule, AdminAuthModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
