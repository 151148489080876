import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ActiveContractsComponent} from "./active-contracts.component";

@NgModule({
  declarations: [ActiveContractsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    ActiveContractsComponent
  ]
})
export class ActiveContractsModule{
}
