import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OpportunityManagementComponent} from "./opportunity-management.component";

@NgModule({
  declarations: [OpportunityManagementComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    OpportunityManagementComponent
  ]
})
export class OpportunityManagementModule{
}
