import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpParams} from "@angular/common/http";
import { BASE_URL } from '../config/globals';

declare var $: any;

@Component({
  selector: 'active-contracts',
  templateUrl: './active-contracts.component.html',
  styleUrls: ['./active-contracts.component.scss']
})
export class ActiveContractsComponent implements OnInit {

  activeContracts: any={};
  trucks: any={};

  requestResult: any={};

  month = '';
  year = '';
  subcontractorId;
  userRole;
  entityId;

  resultMessage;
  confirmText;
  confirmType;

  tempPickupLocation; tempDropoffLocation; tempBrokerName; tempLoadRate; tempLoadRepaymentType;

  tempContractId;
  tempContractRequestId;
  availableTrucksMatchingTrailer: any={};

  constructor(private httpClient: HttpClient, private datePipe: DatePipe, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {

    if (sessionStorage.getItem("userRole") === null || sessionStorage.getItem("userRole") === '' ||
      sessionStorage.getItem("entityId") === null || sessionStorage.getItem("entityId") === '') {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.userRole = sessionStorage.getItem("userRole");
    this.entityId = sessionStorage.getItem("entityId");

    this.getActiveContracts();
  }

  getActiveContracts() {
    const params = new HttpParams().set('subcontrollerId', this.entityId);
    this.httpClient.get(BASE_URL + '/subControllerResource/getActiveContracts', {params}).subscribe((res) => {
      this.activeContracts = res;
    });
  }


  confirmRequestContract(contractId, trailerType) {

    this.getAvailableTrucksForContract(trailerType);

    this.confirmType = 'REQUEST';
    this.tempContractId = contractId;

    //this.confirmText = 'Confirm request to contract for ' + brokerName + '?';
    //$('#truckSelect').modal('show');
  }

  confirmRemoveContractRequest(contractRequestId, pickupLocation, dropoffLocation, brokerName, loadRate, loadPaymentType) {

    this.confirmType = 'REMOVE';
    this.tempContractRequestId = contractRequestId;
    this.tempPickupLocation = pickupLocation;
    this.tempDropoffLocation = dropoffLocation;
    this.tempBrokerName = brokerName;
    this.tempLoadRate = loadRate;
    this.tempLoadRepaymentType = loadPaymentType;

    this.confirmText = 'Cancel contract request?';
    $('#confirmModal').modal('show');
  }


  getAvailableTrucksForContract(trailerType) {
    const params = new HttpParams().set('subcontrollerId', this.entityId).set("userRole", this.userRole).set("trailerType", trailerType);
    this.httpClient.get(BASE_URL + '/subControllerResource/getAvailableTrucksWithTrailerSpecified', {params}).subscribe((res) => {
      this.availableTrucksMatchingTrailer = res;
    });
  }

  actionContractRequest() {

    let truckIds = [];

    for(let i=0; i < this.availableTrucksMatchingTrailer.availableTrucks.length; i++) {
      if ((<HTMLInputElement> document.getElementById(this.availableTrucksMatchingTrailer.availableTrucks[i].truckId)).checked) {
        truckIds.push(this.availableTrucksMatchingTrailer.availableTrucks[i].truckId);
      }
    }

    this.httpClient.post(BASE_URL + "/subControllerResource/requestContract",
      {
        "subcontrollerId": this.entityId,
        "userRole": this.userRole,
        "contractId": this.tempContractId,
        "truckIds": truckIds,
      })
      .subscribe(
        data => {
          this.requestResult = data;
          if (this.requestResult.success === true && this.requestResult.httpStatus === "OK") {
            this.resultMessage = 'Contract successfully requested';
            $('#resultModal').modal('show');
            this.getActiveContracts();
          } else {
            this.resultMessage = 'An error occurred adding the trailer. Please contact support';
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'An error occurred adding the trailer. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }

  actionContractRequestCancel() {

    this.httpClient.put(BASE_URL + "/subControllerResource/cancelContractRequest?contractRequestId=" + this.tempContractRequestId + "&subcontrollerId=" + this.entityId + "&userRole=" + this.userRole,

      {
        "contractRequestId": this.tempContractRequestId,
        "subcontrollerId": this.entityId,
        "userRole" : this.userRole
      })

      .subscribe(
        data => {
          this.requestResult = data;
          if (this.requestResult.success === true && this.requestResult.httpStatus === "OK") {
            this.resultMessage = 'Request successfully cancelled';
            this.getActiveContracts()
            $('#resultModal').modal('show');
          } else {
            this.resultMessage = 'An error occurred removing driver from truck. Please contact support';
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'An error occurred linking driver to truck. Please contact support';
          $('#resultModal').modal('show');
        }
      );
    }
}
