import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpParams} from  "@angular/common/http";
import {ActivatedRoute} from '@angular/router';
import {Observable} from "rxjs";
import {MouseEvent} from "@agm/core";
import { BASE_URL } from '../config/globals';

declare var $: any;

@Component({
  selector: 'app-load',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.scss']
})
export class LoadComponent implements OnInit {

  data: any={};
  coordinates: any={};
  private loadId = '';

  latitude = -26.21218;
  longitude = 27.692981;
  lastUpdatedtime = "UNSPECIFIED";

  entityId;
  userRole;

  zoom = 14;

  emailShareResponse;

  alive = true;
  resultMessage;
  count = 0;

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute) {

    Observable.timer(0,10000)
      .takeWhile(() => this.alive) // only fires when component is alive
      .subscribe(() => {
        this.getLoadGpsCoordinates();
      });

    Observable.timer(0,10000)
      .takeWhile(() => this.alive) // only fires when component is alive
      .subscribe(() => {
        this.getLoadStatus();
      });

  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.loadId = params['id'];
    });
    this.getLoadStatus();

    this.entityId = sessionStorage.getItem("entityId");
    this.userRole = sessionStorage.getItem("userRole");

    if (this.userRole === null || this.userRole === '') {
      this.userRole = 'GUEST';
    }
  }

  getLoadStatus(){
    //this.loadId = "60822429-f53e-4f02-8dd6-56b77d62dcb7";
    const params = new HttpParams().set('loadId', this.loadId);
    this.httpClient.get(BASE_URL + '/loadResource/getLoadInfo',{params}).subscribe((res)=>{
      //console.log(res);
      this.data = res;
    });
  }

  getLoadGpsCoordinates() {
    const params = new HttpParams().set('loadId', this.loadId);
    this.httpClient.get(BASE_URL + '/loadResource/getCurrentLoadGpsCoordinates',{params}).subscribe((res)=>{
      this.coordinates = res;
      this.latitude = +this.coordinates.currentGpsCoordiantes.latitude;
      this.longitude = +this.coordinates.currentGpsCoordiantes.longitude;
      this.lastUpdatedtime = this.coordinates.currentGpsCoordiantes.lastUpdateTime;
    });
  }

  submitLoadInfo() {

    let recipient = (<HTMLInputElement> document.getElementById('email')).value;

    this.httpClient.put(BASE_URL + "/loadResource/shareLoadByEmail?entityId=" + this.entityId + "&userRole=" + this.userRole + "&loadId=" + this.loadId + "&recipientEmail=" + recipient,

      {
        "entityId": this.entityId,
        "userRole": this.userRole,
        "loadId": this.loadId,
        "recipientEmail":recipient
      })

      .subscribe(
        data => {
          this.emailShareResponse = data;
          if (this.emailShareResponse.success === true && this.emailShareResponse.httpStatus === "OK") {
            this.resultMessage = this.emailShareResponse.message;
            $('#resultModal').modal('show');
          } else {
            this.resultMessage = '';
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'An error occurred dispatching your email. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }

  ngOnDestroy(){
    this.alive = false; // switches your IntervalObservable off
  }

}
