import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-error403',
  templateUrl: './error403.component.html',
  styleUrls: ['./error403.component.scss']
})
export class Error403Component implements OnInit {

  constructor(private activatedRoute: ActivatedRoute) { }

  message;

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['message'] != null) {
        this.message = params['message'];
      }
    });
  }

}
