import {Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import {MapsAPILoader, MouseEvent } from '@agm/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DomSanitizer} from '@angular/platform-browser';
import {DatePipe} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer'
import { BASE_URL } from '../config/globals';

declare var $: any;
declare var google;

@Component({
  selector: 'app-add-opportunity',
  templateUrl: './add-opportunity.component.html',
  styleUrls: ['./add-opportunity.component.scss']
})
export class AddOpportunityComponent implements OnInit {

  dataResults: any = {};


  opportunityFormValidation: FormGroup;
  entityId;
  userRole;

  resultMessage;
  confirmText;
  confirmType;

  constructor(private httpClient: HttpClient, public sanitizer: DomSanitizer,
              private datePipe: DatePipe, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {

    if (sessionStorage.getItem("entityId") !== null && sessionStorage.getItem("entityId") !== '') {
      this.entityId = sessionStorage.getItem("entityId");
    }

    if (sessionStorage.getItem("userRole") !== 'BUSINESS' && sessionStorage.getItem("userRole") !== 'BUSINESSADMIN' && sessionStorage.getItem("userRole") !== 'CONSULTANT') {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.userRole = sessionStorage.getItem("userRole");

    this.validateOpportunity();
  }


  validateOpportunity() {

    //validating fields adding an opportunity
    this.opportunityFormValidation = new FormGroup({

      opportunityTitle: new FormControl('', [
        Validators.required
      ]),
      truckCount: new FormControl('', [
        Validators.required
      ]),
      description: new FormControl('', [
        Validators.required
      ]),
      trailerType: new FormControl('', [
        Validators.required
      ])
    });
  }


  confirmAddNewOpportunity() {
    this.confirmText = 'Confirm addition of new subcontractor requirement?';
    $('#confirmModal').modal('show');
  }

  cancel() {
    this.redirect();
  }

  complete() {
    this.redirect();
  }

  redirect() {

    if (this.userRole === 'BUSINESS') {
      window.location.href = "/business?id=" + this.entityId;
    } else if (this.userRole === 'BUSINESSADMIN') {
      window.location.href = "/business-admin?id=" + this.entityId;
    } else if (this.userRole === 'CONSULTANT') {
      window.location.href = "/consultant?consultantId=" + this.entityId;
    } else if (this.userRole === 'SUBCONTROLLER') {
      window.location.href = "/sub-controller";
    } else if (this.userRole === 'SUBCONTRACTOR') {
      window.location.href = "/sub-contractor?id=" + this.entityId;
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }
  }

  addOpportunity() {

    this.httpClient.post(BASE_URL + "/businessResource/addNewOpportunity",
      {
        "entityId": this.entityId,
        "userRole": this.userRole,
        "title": (<HTMLInputElement> document.getElementById("opportunityTitle")).value,
        "truckCount": (<HTMLInputElement> document.getElementById("truckCount")).value,
        "description": (<HTMLInputElement> document.getElementById("description")).value,
        "trailerType": (<HTMLInputElement> document.getElementById("trailerType")).value
      })
      .subscribe(
        data => {
          this.dataResults = data;
          if (this.dataResults.success === true && this.dataResults.httpStatus === "OK") {
            this.resultMessage = this.dataResults.message;
            $('#resultModal').modal('show');
          } else {
            this.resultMessage = this.dataResults.message;;
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = "An error occurred adding the opportunity. Please contact support";
          $('#resultModal').modal('show');
        }
      );
  }
}
