import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ViewLoadsComponent} from "./viewLoads.component";
import { JwPaginationComponent } from 'jw-angular-pagination';
//import {NgxPaginationModule} from 'ngx-pagination';

@NgModule({
  declarations: [ViewLoadsComponent, JwPaginationComponent],
  imports: [
    CommonModule
  ],
  exports: [
    ViewLoadsComponent
  ]
})
export class ViewLoadsModule{
}
