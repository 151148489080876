import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {HttpParams} from "@angular/common/http";
import {DatePipe} from '@angular/common';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { BASE_URL } from '../config/globals';

declare var $: any;

/**
 *
 * @author Megan
 */

@Component({
  selector: 'manage-consultants',
  templateUrl: './manage-business-admin.component.html',
  styleUrls: ['./manage-business-admin.component.scss']
})
export class ManageBusinessAdminComponent implements OnInit {

  data: any = {};
  businessAdminList: any = {};
  consultantsLinkedToBusinessAdmin: any = {};

  dataResults: any = {};

  tempBusinessAdminId;
  businessId;

  resultMessage;
  confirmText;
  confirmType;

  userRole;
  entityId;

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private datePipe: DatePipe) {
  }

  ngOnInit() {

    if (sessionStorage.getItem("userRole") === null || sessionStorage.getItem("userRole") !== 'BUSINESS') {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    if (sessionStorage.getItem("entityId") !== null && sessionStorage.getItem("entityId") !== '') {
      this.businessId = sessionStorage.getItem("entityId");
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.userRole = sessionStorage.getItem("userRole");
    this.entityId = sessionStorage.getItem("entityId");

    this.getBusinessAdmins();
  }

  /**
   * Gets the business admin list
   */
  getBusinessAdmins() {
    const params = new HttpParams().set('businessId', this.businessId);
    this.httpClient.get(BASE_URL + '/businessResource/getBusinessAdmins',{params}).subscribe((res)=>{
      //console.log(res);
      this.businessAdminList = res;
    });
  }

  /**
   * Gets the consultants linked to the business admin selected
   * @param businessAdminId
   */
  getConsultantsForBusinessAdmin(businessAdminId){
    this.tempBusinessAdminId = businessAdminId;
    const params = new HttpParams().set('businessAdminId', businessAdminId);
    this.httpClient.get(BASE_URL + '/businessAdminResource/getConsultantsLinkedToBusinessAdmin',{params}).subscribe((res)=>{
      this.consultantsLinkedToBusinessAdmin = res;
    });
  }


  /**
   * Assigns a consultant to a business admin
   * @param consultantId
   */
  assignBusinessAdmin(consultantId) {

    let businessAdminId = (<HTMLInputElement> document.getElementById(consultantId)).value;

    if (businessAdminId === null || businessAdminId === '') {
       this.resultMessage = 'Please select a business admin to assign the new consultant to';
       $('#resultModal').modal('show');
    } else {
      this.httpClient.put(BASE_URL + "/assignmentResource/assignConsultantToBusinessAdmin?consultantId=" + consultantId + "&businessAdminId=" + businessAdminId + "&entityId=" + this.entityId,
        {"consultantId" : consultantId,
          "businessAdminId": businessAdminId,
          "entityId": this.entityId
        })
        .subscribe(
          data => {
            this.dataResults = data;
            if (this.dataResults.success === true && this.dataResults.httpStatus === "OK") {
              this.resultMessage = this.dataResults.message;
              $('#resultModal').modal('show');
              this.getConsultantsForBusinessAdmin(this.tempBusinessAdminId);
            } else {
              this.resultMessage = this.dataResults.message;
              $('#resultModal').modal('show');
            }
          },
          error => {
            this.resultMessage = 'Error occurred reassigning the subcontractor. Please contact support';
            $('#resultModal').modal('show');
          }
        );
    }
  }

  cancel() {
    this.redirect();
  }

  complete() {
    this.redirect();
  }

  redirect() {

    if (this.userRole === 'BUSINESS') {
      window.location.href = "/business?id=" + this.entityId;
    } else if (this.userRole === 'BUSINESSADMIN') {
      window.location.href = "/business-admin?id=" + this.entityId;
    } else if (this.userRole === 'CONSULTANT') {
      window.location.href = "/consultant?consultantId=" + this.entityId;
    } else if (this.userRole === 'SUBCONTROLLER') {
      window.location.href = "/sub-controller";
    } else if (this.userRole === 'SUBCONTRACTOR') {
      window.location.href = "/sub-contractor?id=" + this.entityId;
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }
  }
}
