import {NgModule} from "@angular/core";
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AgmCoreModule} from '@agm/core';
import {CommonModule} from "@angular/common";
import {AddConsultantComponent} from "./add-consultant.component";


@NgModule({
  declarations: [AddConsultantComponent],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAR8J9QbcE44i7DJN2vcBaDXzHK52Llf9s',
      libraries: ["places"]
    })
  ],
  exports: [
    AddConsultantComponent
  ]
})
export class AddConsultantModule{
}
