import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Location} from "@angular/common";

/**
 *
 * @author Megan
 */

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  urlPath;
  showing = {
    ready: false,
    mustShow: false
  };

   userRole;
   entityId;

  constructor(private router: Router,
              private location: Location) {
    this.urlPath = this.location.path();
  }

  ngOnInit() {
    //this.userRole = localStorage.getItem("userRole");
    this.userRole = sessionStorage.getItem("userRole");
    this.entityId = sessionStorage.getItem("entityId");
    this.subscribeToRouteEvents();
    this.showing.ready = true;
  }

  subscribeToRouteEvents() {
    if (this.urlPath === "/login" || this.urlPath === "/forgot-password" || this.urlPath === "/pickup-location" || this.urlPath === "/dropoff-location"
      || this.urlPath.includes('/401') || this.urlPath.includes('/403') || this.urlPath.includes('/404') || this.urlPath.includes('/register-business')
      || this.urlPath.includes('/reg-password')) {
      this.showing.mustShow = false;

    } else if (sessionStorage.getItem("userRole") === null || sessionStorage.getItem("userRole") === '') {
      this.showing.mustShow = false;
    }
    else if (this.urlPath) {
      this.showing.mustShow = true;
    }
  }

  redirect() {

    if (this.userRole === 'BUSINESS') {
      window.location.href = "/business?id=" + this.entityId;
    } else if (this.userRole === 'BUSINESSADMIN') {
      window.location.href = "/business-admin?id=" + this.entityId;
    } else if (this.userRole === 'CONSULTANT') {
      window.location.href = "/consultant?consultantId=" + this.entityId;
    } else if (this.userRole === 'SUBCONTROLLER') {
      window.location.href = "/sub-controller";
    } else if (this.userRole === 'SUBCONTRACTOR') {
      window.location.href = "/sub-contractor?id=" + this.entityId;
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(['/login'])
      .then(() => {
        location.reload();
    });
  }


}
