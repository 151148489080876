import {NgModule} from "@angular/core";
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from "@angular/common";
import {DriverManagementComponent} from "./driver.management.component";


@NgModule({
  declarations: [DriverManagementComponent],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule
  ],
  exports: [
    DriverManagementComponent
  ]
})
export class DriverManagementModule {
}
