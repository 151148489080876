import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {ActivatedRoute} from '@angular/router';

declare var $: any;

@Component({
  selector: 'under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: ['./under-construction.component.scss']
})
export class UnderConstructionComponent implements OnInit {

  userRole;
  entityId;
  dateAvailable ;
  personalizedMessage;
  key;

  constructor(private httpClient: HttpClient, private datePipe: DatePipe, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['date'] != null && params['date'] !== '') {
        this.dateAvailable = params['date'];
      }

      if (params['k'] != null && params['k'] !== '') {
        this.key = params['k'];

        if (this.key === 'tZ13') { // available contracts
          this.personalizedMessage = "This page will give you a view of brokers that have posted contracts on which you may request to engage in."
        } else if (this.key === "tZ21") { // active contracts
          this.personalizedMessage = "This page will give you a view of your current active running contracts on which a dashboard will give you a clear overview of its progression.";
        } else if (this.key === "y3D2") {  // contractor management
          this.personalizedMessage = "This page will give you the facility to manage contractors that are linked to you.";
        } else if (this.key === "z4RT") { // brokers management
          this.personalizedMessage = "This page will give you the facility to manage the brokers that you are linked to."
        } else if (this.key === "Yz45G") { // new contract
          this.personalizedMessage = "This page will give you the ability to add a new contract to the system and allow interested parties to engage."
        } else if (this.key === "Yz55G") { // manage contract
          this.personalizedMessage = "This page will give you the ability to manage your contracts."
        }
      }
    });

    if (sessionStorage.getItem("userRole") === null || sessionStorage.getItem("userRole") === '' ||
      sessionStorage.getItem("entityId") === null || sessionStorage.getItem("entityId") === '') {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.userRole = sessionStorage.getItem("userRole");
    this.entityId = sessionStorage.getItem("entityId");
  }

  redirect() {

    if (this.userRole === 'BUSINESS') {
      window.location.href = "/business?id=" + this.entityId;
    } else if (this.userRole === 'BUSINESSADMIN') {
      window.location.href = "/business-admin?id=" + this.entityId;
    } else if (this.userRole === 'CONSULTANT') {
      window.location.href = "/consultant?consultantId=" + this.entityId;
    } else if (this.userRole === 'SUBCONTROLLER') {
      window.location.href = "/sub-controller";
    } else if (this.userRole === 'SUBCONTRACTOR') {
      window.location.href = "/sub-contractor?id=" + this.entityId;
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }
  }
}
