import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {HttpParams} from  "@angular/common/http";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { BASE_URL } from '../config/globals';


declare var $: any;

@Component({
  selector: 'app-add-trailer',
  templateUrl: './add-trailer.component.html',
  styleUrls: ['./add-trailer.component.scss']
})
export class AddTrailerComponent implements OnInit {

  private subcontractorId;
  private subcontrollerId;
  data: any={};
  dataResults: any={};
  formGroupAddTrailer: FormGroup;

  userRole;
  entityId;
  userRoleSubcontractor = false;

  resultMessage;
  resultButtonText;
  resultSuccess = false;

  //BASE_URL = 'https://35.238.154.183/ingFleet/api';

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    this.userRole = sessionStorage.getItem("userRole");
    this.entityId = sessionStorage.getItem("entityId");

    if (this.userRole === 'SUBCONTROLLER' && this.entityId !== null && this.entityId !== '') {
      this.subcontrollerId = this.entityId;
      this.userRoleSubcontractor = false;
    } else if (this.userRole === 'SUBCONTRACTOR' && this.entityId !== null && this.entityId !== '') {
      this.subcontractorId = this.entityId;
      this.userRoleSubcontractor = true;
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }


    /*this.activatedRoute.queryParams.subscribe(params => {
      this.subcontractorId = params['subcontractorId'];
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.subcontrollerId = params['subcontrollerId'];
    });*/

    this.validationTrailer();
  }

  validationTrailer() {
    this.formGroupAddTrailer = new FormGroup({
      trailerVIN: new FormControl('', [
        Validators.required
      ]),
      trailerRegistrationNumber: new FormControl('', [
        Validators.required
      ]),
      trailerMake: new FormControl('', [
        Validators.required
      ]),
      trailerModel: new FormControl('', [
        Validators.required
      ]),
      trailerYear: new FormControl('', [
        Validators.required
      ]),
      trailerStatus: new FormControl('', [
        Validators.required
      ]),
      trailerType: new FormControl('', [
        Validators.required
      ])
    });
  }

  add_Trailer() {
    this.httpClient.post(BASE_URL + "/trailerResource/addTrailer",
      {
        "vin": (<HTMLInputElement> document.getElementById("trailerVIN")).value,
        "registrationNumber": (<HTMLInputElement> document.getElementById("trailerRegistrationNumber")).value,
        "make": (<HTMLInputElement> document.getElementById("trailerMake")).value,
        "model": (<HTMLInputElement> document.getElementById("trailerModel")).value,
        "year": (<HTMLInputElement> document.getElementById("trailerYear")).value,
        "trailerStatus": (<HTMLInputElement> document.getElementById("trailerStatus")).value,
        "trailerType": (<HTMLInputElement> document.getElementById("trailerType")).value,
        "subContractorId": this.subcontractorId,
        "subControllerId": this.subcontrollerId,
      })
      .subscribe(
        data => {
          this.dataResults = data;
          if (this.dataResults.success === true && this.dataResults.httpStatus === "OK") {
            /*console.log("POST Request is successful ", data);
            $('#myAlert5').removeClass('hidden').addClass('show');*/
            this.resultMessage = 'Trailer successfully added';
            this.resultButtonText = 'Continue';
            this.resultSuccess = true;
            $('#resultModal').modal('show');
          } else {
            /*$('#myAlert6').removeClass('hidden').addClass('show');
            console.log("Error", data);*/
            this.resultMessage = 'An error occurred adding the trailer. Please contact support';
            this.resultButtonText = 'Back';
            $('#resultModal').modal('show');
          }
        },
        error => {
          /*console.log("Error", error);
          $('#myAlert6').removeClass('hidden').addClass('show');*/
          this.resultMessage = 'An error occurred adding the trailer. Please contact support';
          this.resultButtonText = 'Back';
          $('#resultModal').modal('show');
        }
      );
  }

  cancel() {
    this.redirect();
  }

  redirect() {

    if (this.userRole === 'BUSINESS') {
      window.location.href = "/business?id=" + this.entityId;
    } else if (this.userRole === 'BUSINESSADMIN') {
      window.location.href = "/business-admin?id=" + this.entityId;
    } else if (this.userRole === 'CONSULTANT') {
      window.location.href = "/consultant?consultantId=" + this.entityId;
    } else if (this.userRole === 'SUBCONTROLLER') {
      window.location.href = "/sub-controller";
    } else if (this.userRole === 'SUBCONTRACTOR') {
      window.location.href = "/sub-contractor?id=" + this.entityId;
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }
  }

  complete() {
    this.redirect();
  }

  assignToTruck() {
    alert('working on it');
    this.redirect();
  }

}
