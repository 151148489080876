import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {HttpParams} from  "@angular/common/http";
import {Location} from '@angular/common';
import {Observable} from "rxjs";
import { BASE_URL } from '../config/globals';


declare var $: any;

@Component({
  selector: 'app-truck',
  templateUrl: './truck.component.html',
  styleUrls: ['./truck.component.scss']
})
export class TruckComponent implements OnInit {

  private truckId = '';
  data: any={};
  availableDrivers: any={};
  availableTrailers: any={};
  linkedTrailers: any={};
  entityId;
  userRole;
  driversLinkedToTruck: any={};
  alive = true;

  resultMessage;

  allocateDriverResponse;
  removeDriverFromTruckResponse;

  linkTrailerResponse;
  removeTrailerResponse;

  confirmText;
  confirmType;

  driverId;
  trailerId;

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private location: Location) {

    Observable.timer(0,15000)
      .takeWhile(() => this.alive) // only fires when component is alive
      .subscribe(() => {
        console.log("Polling...");
        this.getAvailableDrivers();
        this.getDriversLinkedToTruck();
        this.getAvailableTrailers();
        this.getLinkedTrailers();
      });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.truckId = params['id'];
    });

    this.entityId = sessionStorage.getItem("entityId");
    this.userRole = sessionStorage.getItem("userRole");

    this.getTruckInfo();
    this.getAvailableDrivers();
    this.getDriversLinkedToTruck();
    this.getAvailableTrailers();
    this.getLinkedTrailers();
  }

  /**
   * This gets the information about the truck
   */
  getTruckInfo() {
    const params = new HttpParams().set('truckId', this.truckId);
    this.httpClient.get(BASE_URL + '/truckResource/getTruckInfo', {params}).subscribe((res) => {
      //console.log(res);
      this.data = res;
    });
  }

  /**
   * This function gets all the drivers not allocated to a truck
   */
  getAvailableDrivers() {
    const params = new HttpParams().set('entityId', this.entityId).set("userRole", this.userRole);
    this.httpClient.get(BASE_URL +  '/driverResource/getAvailableDrivers', {params}).subscribe((res) => {
      //console.log(res);
      this.availableDrivers = res;
    });
  }

  removeDriverFromTruck() {

    this.httpClient.put(BASE_URL + "/truckResource/revokeDriverFromTruck?truckId=" + this.truckId + "&driverId=" + this.driverId,

      {
        "driverId": this.driverId,
        "truckId": this.truckId
      })

      .subscribe(
        data => {
          this.removeDriverFromTruckResponse = data;
          if (this.removeDriverFromTruckResponse.success === true && this.removeDriverFromTruckResponse.httpStatus === "OK") {
            this.resultMessage = 'Driver successfully removed';
            this.getAvailableDrivers();
            this.getDriversLinkedToTruck();
            $('#resultModal').modal('show');
          } else {
            this.resultMessage = 'An error occurred removing driver from truck. Please contact support';
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'An error occurred linking driver to truck. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }

  confirmRemoveDriver(driverId) {
    this.driverId = driverId;
    this.confirmType = 'REMOVE-DRIVER';
    this.confirmText = 'Remove driver from truck?';
    $('#confirmModal').modal('show');
  }

  confirmAllocateDriver(driverId) {
    this.driverId = driverId;
    this.confirmType = 'ALLOCATE-DRIVER';
    this.confirmText = 'Link driver to truck?';
    $('#confirmModal').modal('show');
  }

  confirmLinkTrailer(trailerId) {
    this.trailerId = trailerId;
    this.confirmType = 'LINK-TRAILER';
    this.confirmText = 'Link this trailer to truck?';
    $('#confirmModal').modal('show');
  }

  confirmRemoveTrailer(trailerId) {
    this.trailerId = trailerId;
    this.confirmType = 'REMOVE-TRAILER';
    this.confirmText = 'Remove this trailer from truck?';
    $('#confirmModal').modal('show');
  }

  /**
   * Allocates a driver to a truck
   * @param driverId
   */
  allocateDriver() {

    this.httpClient.put(BASE_URL + "/truckResource/assignDriverToTruck?truckId=" + this.truckId + "&driverId=" + this.driverId + "&override=false&subControllerId=" + this.entityId,

      {
        "driverId": this.driverId,
        "truckId": this.truckId,
        "override": false,
        "subControllerId": this.entityId
      })

      .subscribe(
        data => {
          this.allocateDriverResponse = data;
          if (this.allocateDriverResponse.success === true && this.allocateDriverResponse.httpStatus === "OK") {
            this.resultMessage = 'Driver successfully linked';
            this.getAvailableDrivers();
            this.getDriversLinkedToTruck();
            $('#resultModal').modal('show');
          } else {
            this.resultMessage = 'An error occurred linking driver to truck. Please contact support';
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'An error occurred linking driver to truck. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }

  /**
   * Gets drivers linked to truck
   */
  getDriversLinkedToTruck() {
    const params = new HttpParams().set('truckId', this.truckId);
    this.httpClient.get(BASE_URL +  '/driverResource/getDriversLinkedToTrucks', {params}).subscribe((res) => {
      //console.log(res);
      this.driversLinkedToTruck = res;
    });
  }

  ngOnDestroy(){
    this.alive = false; // switches your IntervalObservable off
  }

  getAvailableTrailers() {

    const params = new HttpParams().set('subcontractorId', '').set('subcontrollerId', this.entityId);
    this.httpClient.get(BASE_URL + '/trailerResource/getAvailableTrailers', {params}).subscribe((res) => {
      //console.log(res);
      this.availableTrailers = res;
    });
  }


  getLinkedTrailers() {

    const params = new HttpParams().set('truckId', this.truckId);
    this.httpClient.get(BASE_URL + '/trailerResource/getTrailersLinkedToTruck', {params}).subscribe((res) => {
      //console.log(res);
      this.linkedTrailers = res;

    });
  }

  removeTrailerFromTruck() {
    this.httpClient.put(BASE_URL + "/trailerResource/unassignTrailerAndTruck?truckId=" + this.truckId + "&trailerId=" + this.trailerId,

      {
        "truckId": this.truckId,
        "trailerId": this.trailerId
      })

      .subscribe(
        data => {
          this.removeTrailerResponse = data;
          if (this.removeTrailerResponse.success === true && this.removeTrailerResponse.httpStatus === "OK") {
            this.resultMessage = 'Trailer successfully removed';
            $('#resultModal').modal('show');
            this.getAvailableTrailers();
            this.getLinkedTrailers();
          } else {
            this.resultMessage = 'An error occurred removing the trailer from the truck. Please contact support';
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'An error occurred removing the trailer from the truck. Please contact support';
          $('#resultModal').modal('show');
        }
      );
    $('#resultModal').modal('show');
  }

  linkTruckToTrailer() {
    this.httpClient.put(BASE_URL + "/trailerResource/assignTrailerToTruck?truckId=" + this.truckId + "&trailerId=" + this.trailerId,

      {
        "truckId": this.truckId,
        "trailerId": this.trailerId
      })

      .subscribe(
        data => {
          this.linkTrailerResponse = data;
          if (this.linkTrailerResponse.success === true && this.linkTrailerResponse.httpStatus === "OK") {
            this.resultMessage = 'Trailer successfully linked';
            $('#resultModal').modal('show');
            this.getAvailableTrailers();
            this.getLinkedTrailers();
          } else {
            this.resultMessage = this.linkTrailerResponse.message;
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'An error occurred linking the trailer to the truck. Please contact support';
          $('#resultModal').modal('show');
        }
      );
    $('#resultModal').modal('show');
  }

  complete() {

  }
}
