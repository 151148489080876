import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SubContractorComponent} from "./sub-contractor.component";

@NgModule({
  declarations: [SubContractorComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    SubContractorComponent
  ]
})
export class SubContractorModule{
}
