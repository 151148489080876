import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {HttpParams} from  "@angular/common/http";
import {DatePipe} from '@angular/common';
import {Observable} from "rxjs";
import { BASE_URL } from '../config/globals';

declare var $: any;

@Component({
  selector: 'app-sub-controller',
  templateUrl: './sub-controller.component.html',
  styleUrls: ['./sub-controller.component.scss']
})
export class SubControllerComponent implements OnInit {

  data: any={};
  cancelLoadResponse;
  loadsData: any={};
  currentLoads: any={};
  trucks: any={};
  availableTrucks: any={};
  subcontrollerId;
  subcontractorId;
  userRole;
  month = '';
  year = '';

  entityKey;

  tempLoadId;
  tempLoadNumber;
  tempConsultantId;

  resultMessage;
  confirmText;
  confirmType;

  alive = true;

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private datePipe: DatePipe) {

    Observable.timer(0,15000)
      .takeWhile(() => this.alive) // only fires when component is alive
      .subscribe(() => {
        console.log("Polling...");
        this.getAvailableLoads();
        this.getCurrentActiveLoads();
        this.getAvailableTrucks();
      });
  }

  ngOnInit() {

    if (sessionStorage.getItem("userRole") === null || sessionStorage.getItem("userRole") === '' ||
      sessionStorage.getItem("entityId") === null || sessionStorage.getItem("entityId") === '') {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.userRole = sessionStorage.getItem("userRole");

    if (sessionStorage.getItem("userRole") === 'SUBCONTROLLER') {
      if (sessionStorage.getItem("entityId") !== null && sessionStorage.getItem("entityId") !== '') {
        this.subcontrollerId = sessionStorage.getItem("entityId");
      }
    } else {
      this.activatedRoute.queryParams.subscribe(params => {
        // Note, a small hack, if the value is not null, it means we redirecting through the application
        if (params['id'] != null) {
          this.subcontrollerId = params['id'];
        }
        if (params['subcontractorId'] != null) {
          this.subcontractorId = params['subcontractorId'];
        }
      });
    }

    this.entityKey = sessionStorage.getItem("entityKey");

    if (this.subcontrollerId === null || this.subcontrollerId === '') {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.month = this.datePipe.transform(new Date(),"MMMM");
    this.year = this.datePipe.transform(new Date(),"yyyy");
    this.getDashboardInfo();
    this.getAvailableLoads();
    this.getCurrentActiveLoads();
    this.getAvailableTrucks();
    //this.getTrucksWithAssignedDrivers();
  }

  confirmCancelLoad(loadId, loadNumber, consultantId) {
    this.tempLoadId = loadId;
    this.tempLoadNumber = loadNumber;
    this.tempConsultantId = consultantId;

    this.confirmType = 'CANCEL-LOAD';
    this.confirmText = 'Confirm load cancellation?';
    $('#confirmModal').modal('show');
  }

  cancelLoad() {

    this.httpClient.put(BASE_URL + '/loadResource/cancelLoadAllocation?entityId='+this.subcontrollerId+'&userRole='+this.userRole+'&loadId='+ this.tempLoadId,
      {
        "entityId": this.subcontrollerId,
        "userRole": this.userRole,
        "loadId": this.tempLoadId
      }).subscribe(
      res => {
        this.cancelLoadResponse = res;
        this.resultMessage = this.cancelLoadResponse.message;
        $('#resultModal').modal('show');
        //window.location.reload();
      },
      error => {
        this.resultMessage = "An error has occurred. Please contact support.";
        $('#resultModal').modal('show');
      }
    );
  }

  /**
   * This function post a request for the subcontroller to do a load
   * The consultant will have the ability to allocate the load as this request will pop up in their pool
   * of requests
   * @param loadId
   * @param loadNumber
   */
  requestLoad(loadId, loadNumber, consultantId) {
    var truckId = (<HTMLInputElement> document.getElementById(loadId)).value;

    if (truckId != "" && truckId != "--") {
      this.httpClient.put(BASE_URL + '/loadResource/requestLoadBooking?loadId='+loadId+'&loadNumber='+loadNumber+'&subcontractorId='
        +'&subControllerId='+this.subcontrollerId+'&truckId='+truckId+'&consultantId='+consultantId,
        {

          "loadId": loadId,
          "loadNumber": loadNumber,
          "subcontractorId": '',
          "subControllerId": this.subcontrollerId,
          "truckId": truckId,
          "consultantId": consultantId

        }).subscribe(
        res => {
          this.resultMessage = "Load has been successfully requested.";
          $('#resultModal').modal('show');
        },
        error => {
          this.resultMessage = "An error has occurred. Please contact support.";
          $('#resultModal').modal('show');
        }
      );
      window.location.reload();
    } else {
      this.resultMessage = "Please select a truck to request the load against."
      $('#resultModal').modal('show');
    }
  }


  /**
   * Deletes a load request
   * @param loadRequestId
   */
  deleteLoadRequest(loadRequestId) {
      this.httpClient.put(BASE_URL + '/loadResource/deleteLoadRequest?loadRequestId='+loadRequestId,
        {
          "loadRequestId": loadRequestId
        }).subscribe(
        res => {
          alert("Success!");
        },
        error => {
          alert("Error!");
        }
      );

    window.location.reload();
  }


  /**
   * Get the the dashboard information that the logged in controller will see upon
   * login
   */
  getDashboardInfo(){
    const params = new HttpParams().set('subControllerId', this.subcontrollerId);
    this.httpClient.get(BASE_URL + '/subControllerResource/getSubControllerDashboardInfo',{params}).subscribe((res)=>{
      this.data = res;
    });
  }

  /**
   * This function gets the loads available that has been uploaded by the consultants that the
   * controller can request to do (ie, loads in an unassigned state)
   */
  getAvailableLoads() {
    const params = new HttpParams().set('subcontractorId', "").set("subcontrollerId", this.subcontrollerId);
    this.httpClient.get(BASE_URL + '/loadResource/getAvailableLoadsList', {params}).subscribe((res)=>{
      this.loadsData = res;
    });
  }

  /**
   * Gets the current active loads linked to the controller
   */
  getCurrentActiveLoads() {
    const params = new HttpParams().set("subControllerId", this.subcontrollerId);
    this.httpClient.get(BASE_URL + '/subControllerResource/getCurrentActiveLoads', {params}).subscribe((res)=>{
      this.currentLoads = res;
    });
  }

  /**
   * This function gets trucks, their linked drivers and trailers linked to the truck
   */
  getTrucksWithAssignedDrivers() {
    const params = new HttpParams().set('subcontractorId', null).set("subcontrollerId", this.subcontrollerId);
    this.httpClient.get(BASE_URL +'/truckResource/getTrucksWithAssignedDrivers', {params: params}).subscribe((res)=>{
      this.trucks = res;
    });
  }

  /**
   * Gets the trucks that are not assigned to a load currently. This will be used in the select box
   * for requesting a load
   */
  getAvailableTrucks(): any {
    const params = new HttpParams().set("subControllerId", this.subcontrollerId);
    this.httpClient.get(BASE_URL +'/subControllerResource/getAvailableTrucks', {params: params}).subscribe((res)=>{
      this.availableTrucks = res;
    });
    return this.availableTrucks;
  }

  ngOnDestroy(){
    this.alive = false; // switches your IntervalObservable off
  }
}
