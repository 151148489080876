import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LoadComponent} from "./load.component";
import {AgmCoreModule} from '@agm/core';

@NgModule({
  declarations: [LoadComponent],
  imports: [
    CommonModule,
    AgmCoreModule
  ],
  exports: [
    LoadComponent
  ]
})
export class LoadModule{
}
