import {Component, OnInit } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer'
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { BASE_URL } from '../config/globals';

declare var $: any;
declare var google;

@Component({
  selector: 'app-driver-management',
  templateUrl: './driver.management.component.html',
  styleUrls: ['./driver.management.component.scss']
})
export class DriverManagementComponent implements OnInit {

  response;

  driverRequests;
  currentActiveDrivers;
  subcontrollers;
  entityId;
  userRole;

  formGroupSubcontroller: FormGroup;

  alive = true;

  resultMessage;
  confirmText;
  confirmType;

  driverRecruitToggle = false;
  driverRecruitMessage = 'Your profile is disabled for driver recruitment';
  driverUpdateResponse;
  driverRecruitStatusResponse;


  tempSubcontrollerId;
  tempDriverId;

  constructor(private httpClient: HttpClient) {

    Observable.timer(0,15000)
      .takeWhile(() => this.alive) // only fires when component is alive
      .subscribe(() => {
        this.getDriverLinkRequests();
        this.getCurrentLinkedDrivers();
      });
  }

  ngOnInit() {

    if (sessionStorage.getItem("entityId") !== null && sessionStorage.getItem("entityId") !== '') {
      this.entityId = sessionStorage.getItem("entityId");
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    if (sessionStorage.getItem("userRole") !== null && sessionStorage.getItem("userRole") !== '') {
      this.userRole = sessionStorage.getItem("userRole");
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.getDriverLinkRequests();
    this.getCurrentLinkedDrivers();
    this.getDriverRecruitmentStatus();

    if (this.userRole === 'SUBCONTRACTOR') {
      this.getSubcontrollers();
      //this.validationSubController();
    }
  }

  removeLoader(){
    $( "#loading" ).fadeOut(500, function() {
      // fadeOut complete. Remove the loading div
      $("#loading").remove(); //makes page more lightweight
      $('#loading').css("display","block");
    });
  }


  /*validationSubController() {
    this.formGroupSubcontroller = new FormGroup({
      subcontroller: new FormControl('', [
        Validators.required
      ])
    });
  }*/

  toggleDriverRecruit(enabled) {

    if (enabled) {
      this.driverRecruitToggle = true;
      this.driverRecruitMessage = 'Your profile is enabled for driver recruitment';
    } else {
      this.driverRecruitToggle = false;
      this.driverRecruitMessage = 'Your profile is disabled for driver recruitment';
    }

    this.httpClient.put(BASE_URL + "/subcontractorResource/updateDriveRecruitmentStatus?subcontractorId=" + this.entityId + "&recruitmentStatus=" + enabled,

      {
        "subcontractorId": this.entityId,
        "recruitmentStatus": enabled
      })

      .subscribe(
        data => {
          this.driverUpdateResponse = data;
          if (this.driverUpdateResponse.success === true && this.driverUpdateResponse.httpStatus === "OK") {
            this.resultMessage = 'Status successfully updated';
            $('#resultModal').modal('show');
          } else {
            this.resultMessage = 'An error occurred updating your recruitment status. Please contact support';
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'An error occurred updating your recruitment status. Please contact support';
          $('#resultModal').modal('show');
        }
      );

  }

  getDriverRecruitmentStatus() {

    const params = new HttpParams().set('subcontractorId', this.entityId);
    this.httpClient.get(BASE_URL + '/subcontractorResource/getDriverRecruitmentStatus',{params}).subscribe((res)=>{
      this.driverRecruitStatusResponse = res;

      if (this.driverRecruitStatusResponse.driverRecruitmentEnabled) {
        this.driverRecruitToggle = true;
        this.driverRecruitMessage = 'Your profile is enabled for driver recruitment';
      } else {
        this.driverRecruitToggle = false;
        this.driverRecruitMessage = 'Your profile is disabled for driver recruitment';
      }

    });
  }

  confirmDelinkDriver(driverId) {

    this.tempDriverId = driverId;

    this.confirmType = 'DE-LINK-DRIVER';
    this.confirmText = 'Confirm removal of driver?';
    $('#confirmModal').modal('show');
  }


  confirmAcceptDriver(driverId, driverName, subcontractorId, selectBoxId) {

    this.tempSubcontrollerId = (<HTMLInputElement> document.getElementById(selectBoxId)).value;
    this.tempDriverId = driverId;

    this.confirmType = 'ACCEPT-DRIVER';
    this.confirmText = 'Confirm acceptance of driver ' + driverName + '?`';
    $('#confirmModal').modal('show');
  }

  confirmRejectDriver(driverId, driverName) {

    this.tempDriverId = driverId;

    this.confirmType = 'REJECT-DRIVER';
    this.confirmText = 'Confirm rejection of driver ' + driverName + '?';
    $('#confirmModal').modal('show');
  }

  searchDrivers() {

  }

  /**
   * Delinks a driver from the subcontractor
   */
  delinkDriver() {

    this.httpClient.put(BASE_URL + "/subcontractorResource/removeDriverFromFleet?subcontractorId=" + this.entityId  + "&driverId=" + this.tempDriverId,
      {"subcontractorId" : this.entityId,
        "driverId" : this.tempDriverId
      })
      .subscribe(
        data => {
          this.response = data;
          if (this.response.success === true && this.response.httpStatus === "OK") {

            this.resultMessage = this.response.message;
            $('#resultModal').modal('show');
            this.getDriverLinkRequests();
            this.getCurrentLinkedDrivers();

          } else {
            this.resultMessage = this.response.message;
            $('#resultModal').modal('show');
            this.getDriverLinkRequests();
            this.getCurrentLinkedDrivers();
          }
        },
        error => {
          this.resultMessage = 'An error occurred linking the driver. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }

  /**
   * Rejects a driver link request
   */
  rejectDriver() {

    this.httpClient.put(BASE_URL + "/subcontractorResource/rejectDriverJobApplication?subcontractorId=" + this.entityId  + "&driverId=" + this.tempDriverId,
      {"subcontractorId" : this.entityId,
        "driverId" : this.tempDriverId
      })
      .subscribe(
        data => {
          this.response = data;
          if (this.response.success === true && this.response.httpStatus === "OK") {

            this.resultMessage = this.response.message;
            $('#resultModal').modal('show');
            this.getDriverLinkRequests();
            this.getCurrentLinkedDrivers();

          } else {
            this.resultMessage = this.response.message;
            $('#resultModal').modal('show');
            this.getDriverLinkRequests();
            this.getCurrentLinkedDrivers();
          }
        },
        error => {
          this.resultMessage = 'An error occurred linking the driver. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }

  /**
   * Links a driver to the subcontractor and subcontroller
   */
  acceptDriver() {

    this.httpClient.put(BASE_URL + "/subcontractorResource/acceptDriverJobRequest?subcontractorId=" + this.entityId + "&subcontrollerId="+this.tempSubcontrollerId + "&driverId=" + this.tempDriverId,
      {"subcontractorId" : this.entityId,
             "subcontrollerId": this.tempSubcontrollerId,
             "driverId" : this.tempDriverId
      })
      .subscribe(
        data => {
          this.response = data;
          if (this.response.success === true && this.response.httpStatus === "OK") {

            this.resultMessage = this.response.message;
            $('#resultModal').modal('show');
            this.getDriverLinkRequests();
            this.getCurrentLinkedDrivers();

          } else {
            this.resultMessage = this.response.message;
            $('#resultModal').modal('show');
            this.getDriverLinkRequests();
            this.getCurrentLinkedDrivers();
          }
        },
        error => {
          this.resultMessage = 'An error occurred linking the driver. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }


  /**
   * Gets the driver requests to the subcontractor
   */
  getDriverLinkRequests() {

    let params;

    if (this.userRole === 'SUBCONTROLLER') {
      params = new HttpParams().set('subcontractorId', '').set('subcontrollerId', this.entityId);

    } else if (this.userRole === 'SUBCONTRACTOR') {
      params = new HttpParams().set('subcontractorId', this.entityId).set('subcontrollerId', '');
    }

    this.httpClient.get(BASE_URL + '/subcontractorResource/getDriverToSubcontractorRequests',{params}).subscribe((res)=>{
      this.driverRequests = res;
    });
  }

  refreshDriverRequests() {
    alert("refreshing driver requests");
  }

  getSubcontrollers() {

    const params = new HttpParams().set('subcontractorId', this.entityId);

    this.httpClient.get(BASE_URL + '/subcontractorResource/getSubcontrollersLinkedToSubcontractor',{params}).subscribe((res)=>{
      this.subcontrollers = res;
    });
  }

  /**
   * Gets drivers currently linked to the subcontractor
   */
  getCurrentLinkedDrivers() {

    let params;

    if (this.userRole === 'SUBCONTROLLER') {
      params = new HttpParams().set('subcontractorId', '').set('subcontrollerId', this.entityId);

    } else if (this.userRole === 'SUBCONTRACTOR') {
      params = new HttpParams().set('subcontractorId', this.entityId).set('subcontrollerId', '');
    }

    this.httpClient.get(BASE_URL + '/subcontractorResource/getDriversLinkedToSubcontractor',{params}).subscribe((res)=>{
      this.currentActiveDrivers = res;
    });
  }

  ngOnDestroy(){
    this.alive = false; // switches your IntervalObservable off
  }
}
