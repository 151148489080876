import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SubControllerComponent} from "./sub-controller.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@NgModule({
  declarations: [SubControllerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    SubControllerComponent
  ]
})
export class SubControllerModule{
}
