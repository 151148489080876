import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TrailerComponent} from "./trailer.component";

@NgModule({
  declarations: [TrailerComponent],
  imports: [
    CommonModule
  ],
  exports: [
    TrailerComponent
  ]
})
export class TrailerModule{
}
