import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ManageBusinessAdminComponent} from "./manage-business-admin.component";

@NgModule({
  declarations: [ManageBusinessAdminComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    ManageBusinessAdminComponent
  ]
})
export class ManageBusinessAdminModule{
}
