import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpParams} from "@angular/common/http";
import { BASE_URL } from '../config/globals';

declare var $: any;

@Component({
  selector: 'view-app-sub-contractor',
  templateUrl: './contract-requests.component.html',
  styleUrls: ['./contract-requests.component.scss']
})
export class ContractRequestsComponent implements OnInit {

  contractRequests: any={};
  response: any={};
  consultants: any={};

  tempSubcontractorId;
  tempContractRequestId;
  tempSubcontractorRegName;
  tempSubcontractorTruckCount
  tempConsultantId;

  entityId;
  userRole;
  contractId;
  contractNumber;

  resultMessage;
  confirmText;
  confirmType;

  constructor(private httpClient: HttpClient, private datePipe: DatePipe, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {

    if (sessionStorage.getItem("entityId") === null || sessionStorage.getItem("entityId") === '' ||
        sessionStorage.getItem("userRole") === null || sessionStorage.getItem("userRole") === '') {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.entityId = sessionStorage.getItem("entityId");
    this.userRole = sessionStorage.getItem("userRole");
    this.contractId = sessionStorage.getItem("contractId");
    this.contractNumber = sessionStorage.getItem("contractNumber");

    this.getContractRequests();
  }

  getContractRequests() {
    const params = new HttpParams().set('entityId', this.entityId).set('userRole', this.userRole).set("contractId", this.contractId);
    this.httpClient.get(BASE_URL + '/contractResource/getContractRequests', {params}).subscribe((res) => {
        this.contractRequests = res;
    });
  }

  confirmAcceptContractRequest(contractRequestId, subcontractorRegName, truckCount) {
    this.tempSubcontractorRegName = subcontractorRegName;
    this.tempSubcontractorTruckCount = truckCount;
    this.tempContractRequestId = contractRequestId;

    this.confirmType = 'ALLOCATE';

    this.confirmText = 'Allocate contract?';
    $('#confirmModal').modal('show');
  }

  confirmRejectContractRequest(contractRequestId, subcontractorRegName, truckCount) {
    this.tempSubcontractorRegName = subcontractorRegName;
    this.tempSubcontractorTruckCount = truckCount;
    this.tempContractRequestId = contractRequestId;

    this.confirmType = 'REJECT';

    this.confirmText = 'Reject contract request?';
    $('#confirmModal').modal('show');
  }

  confirmRemoveContractAllocation(contractRequestId, subcontractorRegName, truckCount) {
    this.tempSubcontractorRegName = subcontractorRegName;
    this.tempSubcontractorTruckCount = truckCount;
    this.tempContractRequestId = contractRequestId;

    this.confirmType = 'REMOVE';

    this.confirmText = 'Remove contract allocation?';
    $('#confirmModal').modal('show');
  }


  allocateContract() {

    this.httpClient.put(BASE_URL + "/contractResource/assignContractorToContract?contractRequestId=" + this.tempContractRequestId + "&entityId=" + this.entityId + "&userRole=" + this.userRole,
      {"contractRequestId" : this.tempContractRequestId,
             "entityId" : this.entityId,
             "userRole" : this.userRole
      })
      .subscribe(
        data => {
          this.response = data;
          if (this.response.success === true && this.response.httpStatus === "OK") {
            this.resultMessage = this.response.message;
            $('#resultModal').modal('show');
            this.getContractRequests();
          } else {
            this.resultMessage = this.response.message;
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'Error occurred allocating the contract. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }


  rejectContractRequest() {

    this.httpClient.put(BASE_URL + "/contractResource/rejectContractRequest?contractRequestId=" + this.tempContractRequestId + "&entityId=" + this.entityId + "&userRole=" + this.userRole,
      {"contractRequestId" : this.tempContractRequestId,
        "entityId" : this.entityId,
        "userRole" : this.userRole
      })
      .subscribe(
        data => {
          this.response = data;
          if (this.response.success === true && this.response.httpStatus === "OK") {
            this.resultMessage = this.response.message;
            $('#resultModal').modal('show');
            this.getContractRequests();
          } else {
            this.resultMessage = this.response.message;
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'Error occurred allocating the contract. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }


  removeContractAllocation() {

    this.httpClient.put(BASE_URL + "/contractResource/removeContractAllocation?contractRequestId=" + this.tempContractRequestId + "&entityId=" + this.entityId + "&userRole=" + this.userRole,
      {"contractRequestId" : this.tempContractRequestId,
        "entityId" : this.entityId,
        "userRole" : this.userRole
      })
      .subscribe(
        data => {
          this.response = data;
          if (this.response.success === true && this.response.httpStatus === "OK") {
            this.resultMessage = this.response.message;
            $('#resultModal').modal('show');
            this.getContractRequests();
          } else {
            this.resultMessage = this.response.message;
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'Error occurred allocating the contract. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }

}
