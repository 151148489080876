import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {HttpParams} from  "@angular/common/http";
import {DatePipe} from '@angular/common';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { BASE_URL } from '../config/globals';


declare var $: any;

@Component({
  selector: 'app-sub-controller',
  templateUrl: './add-sub-controller.component.html',
  styleUrls: ['./add-sub-controller.component.scss']
})
export class AddSubControllerComponent implements OnInit {

  data: any={};
  dataResults: any={};
  formGroupAddSubController: FormGroup;
  private subcontractorId;
  month = '';
  year = '';

  userRole;
  entityId;

  resultMessage;
  confirmText;
  confirmType;

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private datePipe: DatePipe) {
  }

  ngOnInit() {

    if (sessionStorage.getItem("entityId") !== null && sessionStorage.getItem("entityId") !== '') {
      this.subcontractorId = sessionStorage.getItem("entityId");
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.userRole = sessionStorage.getItem("userRole");
    this.entityId = sessionStorage.getItem("entityId");

    this.validationSubController();
  }


  confirmAddSubcontroller() {
    this.confirmType = 'ADD-SUBCONTROLLER';
    this.confirmText = 'Confirm adding of a new controller?';
    $('#confirmModal').modal('show');
  }

  addSubController() {
    this.httpClient.post(BASE_URL + "/subControllerResource/addSubController",
      {
        "name": (<HTMLInputElement> document.getElementById("subcontrollerName")).value,
        "surname": (<HTMLInputElement> document.getElementById("subcontrollerSurname")).value,
        "contactNumber": (<HTMLInputElement> document.getElementById("subcontrollerContactNumber")).value,
        "emailAddress": (<HTMLInputElement> document.getElementById("subcontrollerEmailAddress")).value,
        "subcontractorId": this.subcontractorId,
        "entityStatus": 'PENDING',
        "userName": (<HTMLInputElement> document.getElementById("subcontrollerEmailAddress")).value,
        "password": 'default',
        "identificationNumber": (<HTMLInputElement> document.getElementById("subcontrollerIdentificationNumber")).value
      })
      .subscribe(
        data => {
          this.dataResults = data;
          if (this.dataResults.success === true && this.dataResults.httpStatus === "OK") {
            this.resultMessage = 'Controller successfully added.';
            $('#resultModal').modal('show');
          } else {
            this.resultMessage = 'An error occurred adding the new controller. Please contact support';
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'An error occurred adding the new controller. Please contact support';
          $('#resultModal').modal('show');
        }
      );
   /* $('#subcontrollerModal').modal('hide');
    $('#subcontrollerModal').on('hidden.bs.modal', function () {
      $(this).find('form').trigger('reset');
    })*/
  }


  complete() {
    this.redirect();
  }

  cancel() {
    this.redirect();
  }

  redirect() {

    if (this.userRole === 'BUSINESS') {
      window.location.href = "/business?id=" + this.entityId;
    } else if (this.userRole === 'BUSINESSADMIN') {
      window.location.href = "/business-admin?id=" + this.entityId;
    } else if (this.userRole === 'CONSULTANT') {
      window.location.href = "/consultant?consultantId=" + this.entityId;
    } else if (this.userRole === 'SUBCONTROLLER') {
      window.location.href = "/sub-controller";
    } else if (this.userRole === 'SUBCONTRACTOR') {
      window.location.href = "/sub-contractor?id=" + this.entityId;
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }
  }

  activateController(subControllerId) {

  }

  validationSubController() {
    this.formGroupAddSubController = new FormGroup({
      subcontrollerName: new FormControl('', [
        Validators.required
      ]),
      subcontrollerSurname: new FormControl('', [
        Validators.required
      ]),
      subcontrollerEmailAddress: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
      subcontrollerContactNumber: new FormControl('', [
        Validators.required
      ]),
      subcontrollerIdentificationNumber: new FormControl('', [
        Validators.required
      ])
    });
  }
}
