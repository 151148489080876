import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpParams} from "@angular/common/http";
import { BASE_URL } from '../config/globals';

declare var $: any;

@Component({
  selector: 'view-app-sub-contractor',
  templateUrl: './view-sub-contractor.component.html',
  styleUrls: ['./view-sub-contractor.component.scss']
})
export class ViewSubContractorComponent implements OnInit {

  subcontractorInfo: any={};
  activeLoads: any={};
  completedLoads: any={};

  userRole;
  entityId;

  month = '';
  year = '';
  subcontractorId;

  //BASE_URL = 'https://35.238.154.183/ingFleet/api';

  constructor(private httpClient: HttpClient, private datePipe: DatePipe, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['subcontractorId'] != null) {
        this.subcontractorId = params['subcontractorId'];
      }
    });

    if (sessionStorage.getItem("entityId") !== null &&
        sessionStorage.getItem("entityId") !== '' &&
        sessionStorage.getItem("userRole") !== null &&
        sessionStorage.getItem("userRole") !== '') {
      this.entityId = sessionStorage.getItem("entityId");
      this.userRole = sessionStorage.getItem('userRole');
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.month = this.datePipe.transform(new Date(), "MMMM");
    this.year = this.datePipe.transform(new Date(), "yyyy");

    this.getSubcontractorInfo();
    this.getActiveLoadsForSubcontractor();
    this.getCompletedLoadsForSubcontractor();
  }

  getSubcontractorInfo() {
    const params = new HttpParams().set('subcontractorId', this.subcontractorId);
    this.httpClient.get(BASE_URL + '/subcontractorResource/getSubcontractorInfo', {params}).subscribe((res) => {
      this.subcontractorInfo = res;
    });
  }

  getActiveLoadsForSubcontractor() {
    const params = new HttpParams().set('subcontractorId', this.subcontractorId).set('loadStatus','').set('includeCompleted', 'false').set('entityId', this.entityId).set('userRole', this.userRole);
    this.httpClient.get(BASE_URL + '/loadResource/getLoadsBySubcontractorId', {params}).subscribe((res) => {
      this.activeLoads = res;
    });
  }

  getCompletedLoadsForSubcontractor() {
    const params = new HttpParams().set('subcontractorId', this.subcontractorId).set('loadStatus','COMPLETED').set('includeCompleted', 'true').set('entityId', this.entityId).set('userRole', this.userRole);
    this.httpClient.get(BASE_URL + '/loadResource/getLoadsBySubcontractorId', {params}).subscribe((res) => {
      this.completedLoads = res;
    });
  }


}
