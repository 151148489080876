import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpParams} from  "@angular/common/http";
import {ActivatedRoute} from '@angular/router';
import {FormGroup} from "@angular/forms";
import { BASE_URL } from '../config/globals';

@Component({
  selector: 'app-load',
  templateUrl: './viewLoads.component.html',
  styleUrls: ['./viewLoads.component.scss']
})
export class ViewLoadsComponent implements OnInit {

  loads: any={};
  searchLoadFormGroup: FormGroup;
  //p: number = 1;

  loadNumber;
  pageOfItems: any={};
  searchLoadResponse;


  //BASE_URL = 'https://35.238.154.183/ingFleet/api';

  private consultantId = '';
  private subControllerId = '';
  private subContractorId = '';

  userRole = sessionStorage.getItem("userRole");
  entityId = sessionStorage.getItem("entityId");

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    if (this.userRole === 'BUSINESS' || this.userRole === 'BUSINESSADMIN') {
      this.userRole = 'CONSULTANT';
      this.activatedRoute.queryParams.subscribe(params => {
        this.entityId = params['id'];
      });

    } else  {
      if (this.userRole === 'SUBCONTRACTOR') {
        this.userRole = 'SUBCONTRACTOR';
        this.activatedRoute.queryParams.subscribe(params => {
          this.entityId = params['id'];
        });
      }

      if (this.userRole === 'SUBCONTROLLER') {
        this.userRole = 'SUBCONTROLLER';
        this.activatedRoute.queryParams.subscribe(params => {
          this.entityId = params['id'];
        });
      }
    }

    if (this.entityId === null || this.entityId === '') {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.search();
  }



  /**
   * This will search loads based on the parameters inserted
   */
  search() {

    this.loadNumber = (<HTMLInputElement> document.getElementById("loadNumber")).value;

    if (this.loadNumber === '' || this.loadNumber === null) {
      this.loadNumber = '';
    }

    // todo - get the status, determine the entity on the page and search accordingly

    const params = new HttpParams().set('entityId', this.entityId).set('entityType', this.userRole).set('loadStatus', '').set('loadNumber', this.loadNumber);
    this.httpClient.get(BASE_URL + '/loadResource/searchLoads',{params}).subscribe((res)=>{
      //console.log(res);
      console.log(this.loads);
      this.loads = res;
      this.searchLoadResponse = this.loads.searchedLoadResponse;
    });
  }

  onChangePage(pageOfItems) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

}
