import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ContractManagementComponent} from "./contract-management.component";

@NgModule({
  declarations: [ContractManagementComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    ContractManagementComponent
  ]
})
export class ContractManagementModule{
}
