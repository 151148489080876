import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpParams} from "@angular/common/http";
import { BASE_URL } from '../config/globals';

declare var $: any;

@Component({
  selector: 'contract-management',
  templateUrl: './contract-management.component.html',
  styleUrls: ['./contract-management.component.scss']
})
export class ContractManagementComponent implements OnInit {

  activeContracts: any={};
  response: any={};
  activatecontractStats: any={};

  activateContractRespoonse: any={};

  tempContractId;

  entityId;
  userRole;

  resultMessage;
  confirmText;
  confirmAction;

  constructor(private httpClient: HttpClient, private datePipe: DatePipe, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {

    if (sessionStorage.getItem("entityId") !== null && sessionStorage.getItem("entityId") !== '') {
      this.entityId = sessionStorage.getItem("entityId");
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.userRole = sessionStorage.getItem("userRole");
    this.getActiveContracts();
  }

  getActiveContracts() {
    const params = new HttpParams().set('entityId', this.entityId).set('userRole', this.userRole);
    this.httpClient.get(BASE_URL + '/contractResource/getAllContractsForEntity', {params}).subscribe((res) => {
        this.activeContracts = res;
    });
  }

  confirmRemovalOfContract(tempContractId) {

    this.tempContractId = tempContractId;
    this.confirmAction = 'REMOVE-CONTRACT';
    this.confirmText = 'Confirm removal of contract?';
    $('#confirmModal').modal('show');
  }

  confirmActivateOfContract(tempContractId) {

    this.tempContractId = tempContractId;
    this.confirmAction = 'ACTIVATE-CONTRACT';

    const params = new HttpParams().set('entityId', this.entityId).set('userRole', this.userRole).set('contractId', this.tempContractId);
    this.httpClient.get(BASE_URL + '/contractResource/getContractActivationStats', {params}).subscribe((res) => {
      this.activatecontractStats = res;

      this.confirmText = this.activatecontractStats.contractActivationStatsDto.contractTrucksRequired + ' truck(s) required for the contract,' +
        this.activatecontractStats.contractActivationStatsDto.allocatedTruckCount + ' truck(s) allocated.\n' +
        this.activatecontractStats.contractActivationStatsDto.truckPercentageAllocation + '% allocation.\n\n' +
        'Activate contract?';
      $('#confirmModal').modal('show');
    });
  }

  activateContract() {

    this.httpClient.put(BASE_URL + "/contractResource/activateContract?contractId=" + this.tempContractId + "&entityId=" + this.entityId + "&userRole=" + this.userRole,

      {
        "contractId": this.tempContractId,
        "entityId": this.entityId,
        "userRole": this.userRole
      })

      .subscribe(
        data => {
          this.activateContractRespoonse = data;
          if (this.activateContractRespoonse.success === true && this.activateContractRespoonse.httpStatus === "OK") {

            this.resultMessage = this.activateContractRespoonse.message;
            $('#resultModal').modal('show');
            this.getActiveContracts();
          } else {
            this.resultMessage = this.activateContractRespoonse.message;
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'Error occurred activating the contract. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }

  editContract(contractId) {
    sessionStorage.setItem("contractId", contractId);
    window.location.href = "/update-contract";
  }

  viewContractRequests(contractId, contractNumber) {

    sessionStorage.setItem("contractId", contractId);
    sessionStorage.setItem("contractNumber", contractNumber);
    window.location.href = "/contract-requests";
  }

  actionRemoval() {

    this.httpClient.put(BASE_URL + "/contractResource/deleteContract?contractId=" + this.tempContractId + "&entityId=" + this.entityId + "&userRole=" + this.userRole,
      {"contractId" : this.tempContractId,
              "entityId": this.entityId,
              "userRole": this.userRole
      })
      .subscribe(
        data => {
          this.response = data;
          if (this.response.success === true && this.response.httpStatus === "OK") {

            this.resultMessage = this.response.message;
            $('#resultModal').modal('show');

            this.getActiveContracts();
          } else {

            this.resultMessage = this.response.message;
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'Error occurred removing the contract. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }
}
