import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpParams} from "@angular/common/http";
import { BASE_URL } from '../config/globals';

declare var $: any;

@Component({
  selector: 'view-app-sub-contractor',
  templateUrl: './manage-contractor-requests.component.html',
  styleUrls: ['./manage-contractor-requests.component.scss']
})
export class ManageContractorRequestsComponent implements OnInit {

  linkRequests: any={};
  response: any={};
  consultants: any={};

  tempSubcontractorId;
  tempSubcontractorToBusinessRequestId;
  tempBusinessId;
  tempConsultantId;

  entityId;
  userRole;

  resultMessage;
  confirmText;

  constructor(private httpClient: HttpClient, private datePipe: DatePipe, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {

    if (sessionStorage.getItem("entityId") !== null && sessionStorage.getItem("entityId") !== '') {
      this.entityId = sessionStorage.getItem("entityId");
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.userRole = sessionStorage.getItem("userRole");
    this.getSubcontractorLinkRequests();

    if (this.userRole === "BUSINESS") {
      this.getConsultantsLinkedToBusiness();
    } else if (this.userRole === "BUSINESSADMIN") {
      this.getConsultantsLinkedToBusinessAdmin();
    } else if (this.userRole === "CONSULTANT") {
      this.tempConsultantId = this.entityId;
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

  }

  getSubcontractorLinkRequests() {
    const params = new HttpParams().set('entityId', this.entityId).set('userRole', this.userRole);
    this.httpClient.get(BASE_URL + '/businessResource/getSubcontractorLinkRequests', {params}).subscribe((res) => {
        this.linkRequests = res;
    });
  }

  getConsultantsLinkedToBusinessAdmin() {
    const params = new HttpParams().set('businessAdminId', this.entityId);
    this.httpClient.get(BASE_URL + '/businessAdminResource/getConsultantsLinkedToBusinessAdmin',{params}).subscribe((res)=>{
      this.consultants = res;
    });
  }

  getConsultantsLinkedToBusiness() {
    const params = new HttpParams().set('businessId', this.entityId);
    this.httpClient.get(BASE_URL + '/businessResource/getConsultantsLinkedToBusiness',{params}).subscribe((res)=>{
      this.consultants = res;
    });
  }

  confirmAcceptContractorLink(subcontractorToBusinessRequestId, businessId, subcontractorId) {
    this.tempBusinessId = businessId;
    this.tempSubcontractorId = subcontractorId;
    this.tempSubcontractorToBusinessRequestId = subcontractorToBusinessRequestId;

    if (this.consultants.consultants === null) {
      this.resultMessage = "You need consultant(s) added in your business to accept the link request";
      $('#resultModal').modal('show');

    } else {
      if (this.userRole !== "CONSULTANT") {
        this.tempConsultantId = (<HTMLInputElement> document.getElementById(subcontractorToBusinessRequestId)).value;
      }

      this.confirmText = 'Confirm contractor link request?';
      $('#confirmModal').modal('show');
    }


  }


  actionLink() {

    this.httpClient.put(BASE_URL + "/businessResource/linkBusinessToSubcontractor?subcontractorToBusinessRequestId=" + this.tempSubcontractorToBusinessRequestId + "&businessId=" + this.tempBusinessId + "&subcontractorId=" + this.tempSubcontractorId + "&consultantId=" + this.tempConsultantId,
      {"subcontractorToBusinessRequestId" : this.tempSubcontractorToBusinessRequestId,
             "businessId" : this.tempBusinessId,
             "subcontractorId" : this.tempSubcontractorId,
             "consultantId" : this.tempConsultantId
      })
      .subscribe(
        data => {
          this.response = data;
          if (this.response.success === true && this.response.httpStatus === "OK") {
            this.resultMessage = this.response.message;
            $('#resultModal').modal('show');
            this.getSubcontractorLinkRequests();
          } else {
            this.resultMessage = this.response.message;
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'Error occurred linking the subcontractor. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }
}
