import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ManageSubControllerComponent} from "./manage-sub-controller.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@NgModule({
  declarations: [ManageSubControllerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    ManageSubControllerComponent
  ]
})
export class ManageSubControllerModule{
}
