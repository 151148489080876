import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {HttpClient, HttpParams} from "@angular/common/http";
import { LOGIN_URL } from '../config/globals';

declare var $: any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './register-change-password.component.html',
  styleUrls: ['./register-change-password.component.scss']
})
export class RegisterChangePasswordComponent implements OnInit {

  formGroupPassword: FormGroup;

  token;

  data: any={};
  resultMessage;


  constructor(private activatedRoute: ActivatedRoute, private httpClient: HttpClient) { }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['token'] != null && params['token'] !== '') {
        this.token = params['token'];
        this.validateToken(this.token);
      } else {
        window.location.href = "/403?message=Activation token is invalid. Please contact ING Fleet support at support@ingfleet.co.za";
      }
    });

    this.updatePasswordValidation();

  }

  validateToken(token) {
    const params = new HttpParams().set('token', token);
    this.httpClient.get(LOGIN_URL + '/registrationResource/validateAccount',{params}).subscribe((res)=>{
      this.data = res;

      if (this.data.success === false) {
        window.location.href = "/403?message=Activation token is invalid. Please contact ING Fleet support at support@ingfleet.co.za";
      }
    });
  }

  updatePasswordValidation() {
    this.formGroupPassword = new FormGroup({
      loginPassword: new FormControl('', [
        Validators.required,
      ]),
    });
  }

  resetPassword() {

    let password = (<HTMLInputElement> document.getElementById("loginPassword")).value;

    this.httpClient.put(LOGIN_URL + "/registrationResource/updateUserPasswordByToken?token=" + this.token + "&password=" + password,
      {"token" : this.token,
             "password" : password
      })
      .subscribe(
        res => {
          this.data = res;
          if (this.data.success === true && this.data.httpStatus === "OK") {
            this.resultMessage = 'Password successfully updated';
            $('#resultModal').modal('show');

          } else {
            this.resultMessage = 'An error occurred updating your password. Please contact support';
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'An error occurred updating your password. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }

  complete() {
    window.location.href = '/login';
  }

}
