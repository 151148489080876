import {Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import {MapsAPILoader, MouseEvent } from '@agm/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DomSanitizer} from '@angular/platform-browser';
import {DatePipe} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer'
import { BASE_URL } from '../config/globals';

declare var $: any;
declare var google;

@Component({
  selector: 'app-consultant',
  templateUrl: './add-consultant.component.html',
  styleUrls: ['./add-consultant.component.scss']
})
export class AddConsultantComponent implements OnInit {

  dataResults: any = {};

  userRole;
  entityId;

  formGroupAddConsultant: FormGroup;
  businessAdminId;

  resultMessage;
  confirmText;
  confirmType;

  constructor(private httpClient: HttpClient, public sanitizer: DomSanitizer,
              private datePipe: DatePipe, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {

    if (sessionStorage.getItem("entityId") !== null && sessionStorage.getItem("entityId") !== '') {
      this.businessAdminId = sessionStorage.getItem("entityId");
    }

    if (sessionStorage.getItem("userRole") !== 'BUSINESSADMIN' || this.businessAdminId === null || this.businessAdminId === '') {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.userRole = sessionStorage.getItem("userRole");
    this.entityId = sessionStorage.getItem("entityId");

    this.validationConsultant();
  }


  validationConsultant() {

    //validation Consultant
    this.formGroupAddConsultant = new FormGroup({
      consultantName: new FormControl('', [
        Validators.required
      ]),
      consultantSurname: new FormControl('', [
        Validators.required
      ]),
      consultantContactNumber: new FormControl('', [
        Validators.required
      ]),
      consultantEmailAddress: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
      consultantIdNumber: new FormControl('', [
        Validators.required
      ])
    });
  }


  confirmAddConsultant() {

    this.confirmType = 'ADD-CONSULTANT';
    this.confirmText = 'Confirm consultant addition?';
    $('#confirmModal').modal('show');
  }

  cancel() {
    this.redirect();
  }

  complete() {
    this.redirect();
  }

  redirect() {

    if (this.userRole === 'BUSINESS') {
      window.location.href = "/business?id=" + this.entityId;
    } else if (this.userRole === 'BUSINESSADMIN') {
      window.location.href = "/business-admin?id=" + this.entityId;
    } else if (this.userRole === 'CONSULTANT') {
      window.location.href = "/consultant?consultantId=" + this.entityId;
    } else if (this.userRole === 'SUBCONTROLLER') {
      window.location.href = "/sub-controller";
    } else if (this.userRole === 'SUBCONTRACTOR') {
      window.location.href = "/sub-contractor?id=" + this.entityId;
    } else {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }
  }

  addConsultant() {

    let emailAddress = (<HTMLInputElement> document.getElementById("consultantEmailAddress")).value;

    this.httpClient.post(BASE_URL + "/consultantResource/addConsultant",
      {
        "consultantName": (<HTMLInputElement> document.getElementById("consultantName")).value,
        "consultantSurname": (<HTMLInputElement> document.getElementById("consultantSurname")).value,
        "businessAdminId": this.businessAdminId,
        "contactNumber": (<HTMLInputElement> document.getElementById("consultantContactNumber")).value,
        "emailAddress": emailAddress,
        "entityStatus": 'PENDING',
        "userName": emailAddress,
        "password": 'password',
        "identificationNumber": (<HTMLInputElement> document.getElementById("consultantIdNumber")).value
      })
      .subscribe(
        data => {
          this.dataResults = data;
          if (this.dataResults.success === true && this.dataResults.httpStatus === "OK") {
            this.resultMessage = 'Consultant successfully added.';
            $('#resultModal').modal('show');
          } else {
            this.resultMessage = this.dataResults.message;
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'An error occurred adding the consultant. Please contact support.';
          $('#resultModal').modal('show');
        }
      );
  }
}
