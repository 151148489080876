import {NgModule} from "@angular/core";
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from "@angular/common";
import {RegisterSubcontractorComponent} from "./register-subcontractor.component";


@NgModule({
  declarations: [RegisterSubcontractorComponent],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule
  ],
  exports: [
    RegisterSubcontractorComponent
  ]
})
export class RegisterSubcontractorModule {
}
