import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {HttpClient, HttpParams} from "@angular/common/http";
import { LOGIN_URL } from '../config/globals';

declare var $: any;

@Component({
  selector: 'app-entity-activation',
  templateUrl: './entity-activation.component.html',
  styleUrls: ['./entity-activation.component.scss']
})
export class EntityActivationComponent implements OnInit {

  formActivationGroup: FormGroup;

  regEntityId;
  regEntityRole;
  userRole;
  activate;

  rejecting = false;

  data: any={};
  resultMessage;


  constructor(private activatedRoute: ActivatedRoute, private httpClient: HttpClient) { }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {

        this.regEntityId = params['regEntityId'];
        this.regEntityRole = params['regEntityRole'];
        this.userRole = params['userRole'];
        this.activate = params['activate'];

        if (this.activate === 'NO') {
           this.rejecting = true;
        } else if (this.activate === 'YES') {
          this.verifyEntityAccount();
        }

        if (this.userRole !== 'SUPERUSER') {
          window.location.href = "/403?message=Unauthorized. Please contact ING Fleet support at support@ingfleet.co.za";
        }

    });

    this.updatePasswordValidation();

  }

  updatePasswordValidation() {
    this.formActivationGroup = new FormGroup({
      rejectReason: new FormControl('', [
        Validators.required,
      ]),
    });
  }

  verifyEntityAccount() {

    this.httpClient.put(LOGIN_URL + "/admin/verfiyEntityAccount?regEntityId=" + this.regEntityId + "&regEntityRole=" + this.regEntityRole + "&userRole=SUPERUSER",
      {"regEntityId" : this.regEntityId,
             "regEntityRole": this.regEntityRole,
             "userRole": "SUPERUSER"
      })
      .subscribe(
        res => {
          this.data = res;
          if (this.data.success === true && this.data.httpStatus === "OK") {
            this.resultMessage = 'Entity account has been successfully activated';
            $('#resultModal').modal('show');
          } else {
            this.resultMessage = 'An error occurred activating the entity';
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'An error occurred activating the entity';
          $('#resultModal').modal('show');
        }
      );
  }


  rejectEntityAccount() {

    let rejectionReason = (<HTMLInputElement> document.getElementById("rejectReason")).value;

    this.httpClient.put(LOGIN_URL + "/admin/rejectEntityAccount?regEntityId=" + this.regEntityId + "&regEntityRole=" + this.regEntityRole + "&userRole=SUPERUSER&rejectionReason=" + rejectionReason,
      {"regEntityId" : this.regEntityId,
        "regEntityRole": this.regEntityRole,
        "userRole": "SUPERUSER",
        "rejectionReason": rejectionReason
      })
      .subscribe(
        res => {
          this.data = res;
          if (this.data.success === true && this.data.httpStatus === "OK") {
            this.resultMessage = 'Entity account has been successfully rejected';
            $('#resultModal').modal('show');
          } else {
            this.resultMessage = 'An error occurred rejecting the entity';
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'An error occurred rejecting the entity';
          $('#resultModal').modal('show');
        }
      );
  }


  complete() {
    window.location.href = '/login';
  }

}
