import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TruckManagementComponent} from "./truck-management.component";

@NgModule({
  declarations: [TruckManagementComponent],
  imports: [
    CommonModule
  ],
  exports: [
    TruckManagementComponent
  ]
})
export class TruckManagementModule{
}
