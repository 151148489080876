import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UnderConstructionComponent} from "./under-construction.component";

@NgModule({
  declarations: [UnderConstructionComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    UnderConstructionComponent
  ]
})
export class UnderConstructionModule {
}
