import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {HttpParams} from "@angular/common/http";
import {DatePipe} from '@angular/common';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { BASE_URL } from '../config/globals';

declare var $: any;

/**
 *
 * @author Megan
 */

@Component({
  selector: 'app-business-admin',
  templateUrl: './business-admin.component.html',
  styleUrls: ['./business-admin.component.scss']
})
export class BusinessAdminComponent implements OnInit {

  data: any = {};
  results: any = {};
  //private businessId = localStorage.getItem('businessId');
  private businessAdminId;

  month = '';
  year = '';

  entityKey;
  userRole;

  formGroupAddSubcontractor: FormGroup;
  dataResults: any = {};
  private id = '';

  tempConsultantId;

  resultMessage;
  confirmText;
  confirmType;

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private datePipe: DatePipe) {
  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['id'] != null) {
        this.businessAdminId = params['id'];
      }
    });

    if (this.businessAdminId == null && sessionStorage.getItem("entityId") !== null && sessionStorage.getItem("entityId") !== '') {
      this.businessAdminId = sessionStorage.getItem("entityId");
    }

    if (this.businessAdminId == null) {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    if (this.businessAdminId === null || this.businessAdminId === '') {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.entityKey = sessionStorage.getItem("entityKey");
    this.userRole = sessionStorage.getItem("userRole");

    this.month = this.datePipe.transform(new Date(), "MMMM");
    this.year = this.datePipe.transform(new Date(), "yyyy");
    this.validationSubcontractor();
    this.getBusinessAdminInfo();
    this.get_BusinessAdmin_Dashboard();
  }

  confirmDeactivateConsultant(consultantId) {
    this.tempConsultantId = consultantId;
    this.confirmType = 'DEACTIVATE-CONSULTANT';
    this.confirmText = 'Confirm deactivation of consultant?';
    $('#confirmModal').modal('show');

  }

  confirmActivateConsultant(consultantId) {
    this.tempConsultantId = consultantId;
    this.confirmType = 'ACTIVATE-CONSULTANT';
    this.confirmText = 'Confirm activation of consultant?';
    $('#confirmModal').modal('show');

  }

  activateConsultant() {
    this.resultMessage = 'Under construction';
    $('#resultModal').modal('show');
  }

  deActivateConsultant() {

    /*this.resultMessage = 'Under construction';
    $('#resultModal').modal('show');*/

    this.httpClient.put(BASE_URL + "/consultantResource/deactivateConsultant?consultantId=" + this.tempConsultantId,
      {"consultantId" : this.tempConsultantId
      })
      .subscribe(
        data => {
          this.dataResults = data;
          if (this.dataResults.success === true && this.dataResults.httpStatus === "OK") {
            this.resultMessage = this.dataResults.message;
            $('#resultModal').modal('show');
          } else {
            this.resultMessage = this.dataResults.message;
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'Error occurred deactivating the consultant. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }




  validationSubcontractor() {

    //validation Subcontractor
    this.formGroupAddSubcontractor = new FormGroup({
      registeredName: new FormControl('', [
        Validators.required
      ]),
      tradingName: new FormControl('', [
        Validators.required
      ]),
      registrationNumber: new FormControl('', [
        Validators.required
      ]),
      vatNumber: new FormControl('', [
        Validators.required
      ]),
      Latitude: new FormControl('', [
        Validators.required
      ]),
      Longitude: new FormControl('', [
        Validators.required
      ]),
      contactNumber: new FormControl('', [
        Validators.required
      ]),
      subcontractorUsername: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
      subcontractorPassword: new FormControl('', [
        Validators.required
      ])
    });
  }

  getBusinessAdminInfo() {
    const params = new HttpParams().set('businessAdminId', this.businessAdminId);
    this.httpClient.get(BASE_URL + '/businessAdminResource/getBusinessAdminInfo', {params}).subscribe((res) => {
      //console.log(res);
      this.data = res;
    });
  }

  get_BusinessAdmin_Dashboard() {
    const params = new HttpParams().set('businessAdminId', this.businessAdminId);
    this.httpClient.get(BASE_URL + '/businessAdminResource/getBusinessAdminDashBoardInfo', {params}).subscribe((res) => {
      //console.log(res);
      this.results = res;
    });
  }





}
