import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from '@angular/common/http';
import {Router} from "@angular/router";
import {LOGIN_URL} from "../config/globals";

declare var $: any;

/**
 *
 * @author Megan
 */

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formGroupLogin: FormGroup;
  dataResults: any={};

  constructor(private httpClient: HttpClient, private router: Router,) {
  }

  loginValidation() {
    this.formGroupLogin = new FormGroup({
      loginEmail: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
      loginPassword: new FormControl('', [
        Validators.required
      ])
    });
  }

  tryLogin() {
    let username = (<HTMLInputElement> document.getElementById("loginEmail")).value;
    let password = (<HTMLInputElement> document.getElementById("loginPassword")).value;
    this.httpClient.post(LOGIN_URL + '/authenticationResource/authenticateUser',
      {
        "userName": username,
        "password": password
      })
      .subscribe(
        data => {
          this.dataResults = data;
          if (this.dataResults.success === true && this.dataResults.userType === "ADMIN") {

            sessionStorage.setItem('entityId', this.dataResults.entityId);
            sessionStorage.setItem('userRole', this.dataResults.userType);
            sessionStorage.setItem('entityKey', this.dataResults.entityKey);
            window.location.href = "/admin?id="+this.dataResults.entityId;

          } else if (this.dataResults.success === true && this.dataResults.userType === "BUSINESS") {

            sessionStorage.setItem('entityId', this.dataResults.entityId);
            sessionStorage.setItem('userRole', this.dataResults.userType);
            sessionStorage.setItem('entityKey', this.dataResults.entityKey);
            window.location.href = "/business?id="+this.dataResults.entityId;

          } else if (this.dataResults.success === true && this.dataResults.userType === "BUSINESSADMIN") {

            sessionStorage.setItem('entityId', this.dataResults.entityId);
            sessionStorage.setItem('userRole', this.dataResults.userType);
            sessionStorage.setItem('entityKey', this.dataResults.entityKey);
            window.location.href = "/business-admin?id="+this.dataResults.entityId;

          } else if (this.dataResults.success === true && this.dataResults.userType === "CONSULTANT") {

            sessionStorage.setItem('entityId', this.dataResults.entityId);
            sessionStorage.setItem('userRole', this.dataResults.userType);
            sessionStorage.setItem('entityKey', this.dataResults.entityKey);
            window.location.href = "/consultant?consultantId="+this.dataResults.entityId;


          } else if (this.dataResults.success === true && this.dataResults.userType === "SUBCONTROLLER") {

            sessionStorage.setItem('entityId', this.dataResults.entityId);
            sessionStorage.setItem('userRole', this.dataResults.userType);
            sessionStorage.setItem('entityKey', this.dataResults.entityKey);
            window.location.href = "/sub-controller";

          } else if (this.dataResults.success === true && this.dataResults.userType === "SUBCONTRACTOR") {

            sessionStorage.setItem('entityId', this.dataResults.entityId);
            sessionStorage.setItem('userRole', this.dataResults.userType);
            sessionStorage.setItem('entityKey', this.dataResults.entityKey);
            window.location.href = "/sub-contractor?id="+this.dataResults.entityId;

          }
          else {
            $('#myAlert1').removeClass('hidden').addClass('show');
            console.log("Error", data);
          }
        },
        error => {
          $('#myAlert1').removeClass('hidden').addClass('show');
          console.log("Error", error);
        }
      );
  }

  registerBusiness(entityType){
    window.location.href = "/register-business?t="+entityType;
  }

  registerSubContractor(){
    window.location.href = "/register-subcontractor";
  }

  ngOnInit() {
    this.loginValidation();
  }

}
