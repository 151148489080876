import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { BASE_URL } from '../config/globals';

declare var $: any;

/**
 *
 * @author Megan
 */

@Component({
  selector: 'app-register-subcontractor',
  templateUrl: './register-subcontractor.component.html',
  styleUrls: ['./register-subcontractor.component.scss']
})
export class RegisterSubcontractorComponent implements OnInit {

  formGroupAddSubcontractor: FormGroup;
  dataResults: any={};

  constructor(private httpClient: HttpClient) { }

  ngOnInit() {
    this.validationSubContractor();
  }



  validationSubContractor() {
    //validation Subcontractor
    this.formGroupAddSubcontractor = new FormGroup({
      registeredName: new FormControl('', [
        Validators.required
      ]),
      tradingName: new FormControl('', [
        Validators.required
      ]),
      registrationNumber: new FormControl('', [
        Validators.required
      ]),
      vatNumber: new FormControl('', [
        Validators.required
      ]),
      Latitude: new FormControl('', [
        Validators.required
      ]),
      Longitude: new FormControl('', [
        Validators.required
      ]),
      contactNumber: new FormControl('', [
        Validators.required
      ]),
      subcontractorUsername: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
      subcontractorPassword: new FormControl('', [
        Validators.required
      ])
    });
  }

  /**
   * Adds a subcontractor to the system
   */
  registerSubContractor() {
    this.httpClient.post(BASE_URL + "/subcontractorResource/addSubcontractor",
      {
        "businessId": "",
        "businessAdminId": "",
        "consultantId": "",
        "registeredName": (<HTMLInputElement> document.getElementById("registeredName")).value,
        "tradingName": (<HTMLInputElement> document.getElementById("tradingName")).value,
        "vatNumber": (<HTMLInputElement> document.getElementById("vatNumber")).value,
        "registrationNumber": (<HTMLInputElement> document.getElementById("registrationNumber")).value,
        "longitude": (<HTMLInputElement> document.getElementById("longitude")).value,
        "latitude" : (<HTMLInputElement> document.getElementById("latitude")).value,
        "contactNumber": (<HTMLInputElement> document.getElementById("contactNumber")).value,
        "logo" : null,
        "username": (<HTMLInputElement> document.getElementById("subcontractorUsername")).value,
        "password": (<HTMLInputElement> document.getElementById("subcontractorPassword")).value
      })
      .subscribe(
        data  => {
          this.dataResults = data;
          if (this.dataResults.success === true && this.dataResults.httpStatus === "OK") {
            console.log("POST Request is successful ", data);
            $('#myAlertSuccess1').removeClass('hidden').addClass('show');
          } else {
            $('#myAlertError1').removeClass('hidden').addClass('show');
            console.log("Error", data);
          }
        },
        error  => {
          console.log("Error", error);
          $('#myAlertError1').removeClass('hidden').addClass('show');
        }
      );

  };



}
