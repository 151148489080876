import {Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import {MapsAPILoader, MouseEvent } from '@agm/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DomSanitizer} from '@angular/platform-browser';
import {DatePipe} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer'
import { BASE_URL } from '../config/globals';

declare var $: any;
declare var google;

@Component({
  selector: 'app-consultant',
  templateUrl: './consultant.component.html',
  styleUrls: ['./consultant.component.scss']
})
export class ConsultantComponent implements OnInit {

  data: any={};
  results: any={};
  request: any={};
  info: any={};

  allocateLoadResponse;

  pickupGpsCoordinates = '';
  dropOffGpsCoordinates = '';
  month = '';
  year = '';
  formGroupAddSubcontractor: FormGroup;
  formGroupAddLoad: FormGroup;
  dataResults: any={};
  cancelLoadResponse;

  userRole;
  entityKey;

  alive = true;

  tempLoadId;
  tempLoadStatus;

  tempLoadNumber;
  tempSubcontractorId;
  tempSubcontrollerId;
  tempTruckId;

  resultMessage;
  confirmText;
  confirmType;

  private consultantId;

  constructor(private httpClient: HttpClient, public sanitizer: DomSanitizer,
              private datePipe: DatePipe, private activatedRoute: ActivatedRoute,
              private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone) {

    Observable.timer(0,15000)
      .takeWhile(() => this.alive) // only fires when component is alive
      .subscribe(() => {
        console.log("Polling...");
        this.getConsultantLoadList();
        this.getLoadRequestsForConsultant();
      });
  }

  ngOnInit() {

    // Note, a small hack, if the value is null, it means we redirecting through the application
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['consultantId'] != null) {
        this.consultantId = params['consultantId'];
      }
    });

    if (sessionStorage.getItem("userRole") === null || sessionStorage.getItem("userRole") === '' ||
      sessionStorage.getItem("entityId") === null || sessionStorage.getItem("entityId") === '') {
      sessionStorage.clear();
      window.location.href = "/403?message=Invalid session, please login again.";
    }

    this.userRole = sessionStorage.getItem("userRole");
    this.entityKey = sessionStorage.getItem("entityKey");

    if (this.userRole === 'CONSULTANT') {
      this.consultantId = sessionStorage.getItem("entityId");
    }

    //create search FormControl

    this.month = this.datePipe.transform(new Date(),"MMMM");
    this.year = this.datePipe.transform(new Date(),"yyyy");

    //setTimeout(this.removeLoader, 3000); //wait for page load PLUS two seconds.
    this.getConsultantInfo();
    this.getConsultantDashboardInfo();
    this.getConsultantLoadList();
    this.getLoadRequestsForConsultant();
    this.validationSubContractor();
    this.validationLoad();



  }

  removeLoader(){
    $( "#loading" ).fadeOut(500, function() {
      // fadeOut complete. Remove the loading div
      $("#loading").remove(); //makes page more lightweight
      $('#loading').css("display","block");
    });
  }

  searchLoadsRedirect() {
    window.location.href = "/view-loads?id="+ this.consultantId;
  }

  confirmAllocateLoad(loadId, loadNumber, subcontractorId, subcontrollerId, truckId) {
    this.tempLoadId = loadId;
    this.tempLoadNumber = loadNumber;
    this.tempSubcontractorId = subcontractorId;
    this.tempSubcontrollerId = subcontrollerId;
    this.tempTruckId = truckId;

    this.confirmType = 'ALLOCATE-LOAD';
    this.confirmText = 'Confirm load allocation?';
    $('#confirmModal').modal('show');
  }


  allocateLoad() {

    this.httpClient.post(BASE_URL + "/loadAllocationResource/assignLoad",
      {
        "loadId": this.tempLoadId,
        "loadNumber": this.tempLoadNumber,
        "subcontractorId": this.tempSubcontractorId,
        "subcontrollerId": this.tempSubcontrollerId,
        "truckId": this.tempTruckId,
        "driverId": '',
      })
      .subscribe(
        data => {
          this.allocateLoadResponse = data;
          if (this.allocateLoadResponse.success === true && this.allocateLoadResponse.httpStatus === "OK") {
            this.resultMessage = 'Load successfully allocated';
            $('#resultModal').modal('show');
          } else {
            this.resultMessage = this.allocateLoadResponse.message;
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'Error allocating load. Please contact support';
          $('#resultModal').modal('show');
        }
      );
    window.location.reload();
  }


  confirmDeactivateContractor(contractorId) {
    this.confirmType = 'DEACTIVATE-CONTRACTOR';
    this.tempSubcontractorId = contractorId;

    this.confirmText = 'Are you sure you would like to deactivate this contractor?';
    $('#confirmModal').modal('show');
  }

  deactivateContractor() {
    this.resultMessage = 'Under construction';
    $('#resultModal').modal('show');
  }

  confirmCancelLoad(loadId, loadStatus) {
    this.tempLoadId = loadId;
    this.tempLoadStatus = loadStatus;

    this.confirmType = 'CANCEL-LOAD';

    if (loadStatus !== 'UNASSIGNED') {
      this.confirmText = 'Are you sure you would like to cancel this load as it is already assigned?';
    } else {
      this.confirmText = 'Confirm cancellation of load?';
    }
    $('#confirmModal').modal('show');
  }

  /**
   * Cancels a load
   */
  cancelLoad() {

    this.httpClient.put(BASE_URL + "/loadAllocationResource/cancelLoad?loadId=" + this.tempLoadId,
      {"loadId" : this.tempLoadId
      })
      .subscribe(
        data => {
          this.dataResults = data;
          if (this.dataResults.success === true && this.dataResults.httpStatus === "OK") {
            this.resultMessage = this.dataResults.message;
            $('#resultModal').modal('show');
            this.getConsultantLoadList();
            this.getLoadRequestsForConsultant();
          } else {
            this.resultMessage = this.dataResults.message;
            $('#resultModal').modal('show');
          }
        },
        error => {
          this.resultMessage = 'Error occurred cancelling the load. Please contact support';
          $('#resultModal').modal('show');
        }
      );
  }

  removeAllocation() {

    this.httpClient.put(BASE_URL + '/loadResource/cancelLoadAllocation?entityId='+this.consultantId+'&userRole='+this.userRole+'&loadId='+ this.tempLoadId,
      {
        "entityId": this.consultantId,
        "userRole": this.userRole,
        "loadId": this.tempLoadId
      }).subscribe(
      res => {
        this.cancelLoadResponse = res;
        this.resultMessage = this.cancelLoadResponse.message;
        $('#resultModal').modal('show');
        //window.location.reload();
      },
      error => {
        this.resultMessage = "An error has occurred. Please contact support.";
        $('#resultModal').modal('show');
      }
    );
  }

  validationSubContractor() {
    //validation Subcontractor
    this.formGroupAddSubcontractor = new FormGroup({
      registeredName: new FormControl('', [
        Validators.required
      ]),
      tradingName: new FormControl('', [
        Validators.required
      ]),
      registrationNumber: new FormControl('', [
        Validators.required
      ]),
      vatNumber: new FormControl('', [
        Validators.required
      ]),
      Latitude: new FormControl('', [
        Validators.required
      ]),
      Longitude: new FormControl('', [
        Validators.required
      ]),
      contactNumber: new FormControl('', [
        Validators.required
      ]),
      subcontractorUsername: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
      subcontractorPassword: new FormControl('', [
        Validators.required
      ])
    });
  }

  validationLoad() {
    //validation Load
    this.formGroupAddLoad = new FormGroup({
      loadNumber: new FormControl('', [
        Validators.required
      ]),
      loadTime: new FormControl('', [
        Validators.required
      ]),
      clientName: new FormControl('', [
        Validators.required
      ]),
      loadStatus: new FormControl('', [
        Validators.required
      ])
      /*loadPickupLocation: new FormControl('', [
        Validators.required
      ]),
      loadDropOffLocation: new FormControl('', [
        Validators.required
      ]),*/
    });
  }

  /**
   * This simply returns the basic information about the consultant
   */
  getConsultantInfo(){
    const params = new HttpParams().set('consultantId', this.consultantId);
    this.httpClient.get(BASE_URL + '/consultantResource/getConsultantInfo',{params}).subscribe((res)=>{
      this.data = res;
    });
  }

  /**
   * This returns a overview of the consultants operations in context of all his linked subcontractors
   */
  getConsultantDashboardInfo() {
    const params = new HttpParams().set('consultantId', this.consultantId);
    this.httpClient.get(BASE_URL + '/consultantResource/getConsultantDashboardInfo', {params}).subscribe((res) => {
      this.info = res;
    });
  }

  /**
   * This returns a list of loads added on the system that are not closed. It will give the lastest
   * status of the load as well
   */
  getConsultantLoadList(){
    const params = new HttpParams().set('consultantId', this.consultantId);
    this.httpClient.get(BASE_URL + '/consultantResource/getConsultantLoadList', {params}).subscribe((res)=>{
      this.results = res;
    });
  }

  /**
   * This function gets all the load requests from all the subcontractors linked to the
   * consultant. A load request originates from a consultant adding a load, the load
   * is then added into a load pool against which subcontractors linked to the consultant
   * can issue a request to do the load.
   */
  getLoadRequestsForConsultant(){
    const params = new HttpParams().set('consultantId', this.consultantId);
    this.httpClient.get(BASE_URL + '/consultantResource/getLoadRequestsForConsultant', {params}).subscribe((res)=>{
      this.request = res;
    });
  }

  /**
   * Adds a subcontractor to the system
   */
  addSubContractor() {
    this.httpClient.post(BASE_URL + "/subcontractorResource/addSubcontractor",
      {
        "businessId": "",
        "businessAdminId": "",
        "consultantId": this.consultantId,
        "registeredName": (<HTMLInputElement> document.getElementById("registeredName")).value,
        "tradingName": (<HTMLInputElement> document.getElementById("tradingName")).value,
        "vatNumber": (<HTMLInputElement> document.getElementById("vatNumber")).value,
        "registrationNumber": (<HTMLInputElement> document.getElementById("registrationNumber")).value,
        "longitude": (<HTMLInputElement> document.getElementById("longitude")).value,
        "latitude" : (<HTMLInputElement> document.getElementById("latitude")).value,
        "contactNumber": (<HTMLInputElement> document.getElementById("contactNumber")).value,
        "logo" : null,
        "username": (<HTMLInputElement> document.getElementById("subcontractorUsername")).value,
        "password": (<HTMLInputElement> document.getElementById("subcontractorPassword")).value
      })
      .subscribe(
        data  => {
          this.dataResults = data;
          if (this.dataResults.success === true && this.dataResults.httpStatus === "OK") {
            console.log("POST Request is successful ", data);
            $('#myAlertSuccess1').removeClass('hidden').addClass('show');
          } else {
            $('#myAlertError1').removeClass('hidden').addClass('show');
            console.log("Error", data);
          }
        },
        error  => {
          console.log("Error", error);
          $('#myAlertError1').removeClass('hidden').addClass('show');
        }
      );

    $('#addNewSubContractorModal').modal('hide');
    $('#addNewSubContractorModal').on('hidden.bs.modal', function () {
      $(this).find('form').trigger('reset');
    })
  };

  ngOnDestroy(){
    this.alive = false; // switches your IntervalObservable off
  }
}
