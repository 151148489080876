import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  formGroupEmailPassword: FormGroup;

  constructor() { }

  updatePasswordValidation() {
    this.formGroupEmailPassword = new FormGroup({
      inputEmail: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
    });
  }

  resetPassword() {

  }

  ngOnInit() {
   this.updatePasswordValidation();
  }

}
