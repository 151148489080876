import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SubcontractorProfileComponent} from "./sub-contractor-profile.component";

@NgModule({
  declarations: [SubcontractorProfileComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    SubcontractorProfileComponent
  ]
})
export class SubContractorProfileModule{
}
