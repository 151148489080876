import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SubContractorComponent} from "./sub-contractor.component";
import {ViewSubContractorComponent} from "./view-sub-contractor.component";

@NgModule({
  declarations: [ViewSubContractorComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    ViewSubContractorComponent
  ]
})
export class ViewSubContractorModule{
}
