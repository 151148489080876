import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TruckListComponent} from "./truck-list.component";

@NgModule({
  declarations: [TruckListComponent],
  imports: [
    CommonModule
  ],
  exports: [
    TruckListComponent
  ]
})
export class TruckListModule{
}
