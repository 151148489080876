import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ManageConsultantsComponent} from "./manage-consultants.component";

@NgModule({
  declarations: [ManageConsultantsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    ManageConsultantsComponent
  ]
})
export class ManageConsultantsModule{
}
